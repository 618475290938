import React, { useEffect, useState } from 'react'
import { toUnicode } from 'punycode'
import SVG from 'react-inlinesvg'
import { getRequest } from '../../../services'
import {
  Tooltip,
  useSortableTable,
  IInsightsV1StatisticsRow,
  TableHead,
  map,
  WithTooltip,
  navigateTo,
  varClass,
  formatStat,
  formatCurrency,
  IInsightsV1Statistics,
  Loadable,
  useApiPost,
  newTableSort,
} from '../../shared'
import {
  ignoreHighLowNetworkUuids,
  ignoreWorstNetworkUuids,
} from '../constants'
import moment from 'moment'
import { startOfDay, endOfDay } from 'date-fns'
import { PlaceholderTop } from '../Placeholder'

const performanceTabLabels = {
  'name': 'Merchants',
  'tracker': 'Campaigns',
  'network': 'Networks',
  'channel': 'Sites',
  'page': 'Pages',
}

const performanceViewKey = 'overview-performance-view'

const sortTop = newTableSort((a: any, b: any, key) => {
  if (key === 'cr') {
    return [
      a.clickCount > 0 ? a.conversionCount / a.clickCount : 0,
      b.clickCount > 0 ? b.conversionCount / b.clickCount : 0,
    ]
  }
  if (key === 'ecr') {
    return [
      a.conversionSale > 0 ? a.conversionTotalCommission / a.conversionSale : 0,
      b.conversionSale > 0 ? b.conversionTotalCommission / b.conversionSale : 0,
    ]
  }
  if (key === 'epc') {
    return [
      a.clickCount > 0 ? a.conversionTotalCommission / a.clickCount : 0,
      b.clickCount > 0 ? b.conversionTotalCommission / b.clickCount : 0,
    ]
  }
})

export const PerformanceTable = (props) => {
  const { data: totals, selectedChannelUuids, isEmpty, currentUser } = props

  const [view, setView] = useState(
    () => localStorage.getItem(performanceViewKey) || 'best'
  )
  const [tab, setTab] = useState(() => Object.keys(performanceTabLabels)[0])

  let fromDate = moment().toDate()
  const toDate = moment().subtract(1, 'days').toDate()
  let ignoredNetworks: string[] | undefined
  // let prevFromDate = moment().add(1, 'day').toDate()
  // let prevToDate = moment().add(1, 'day').toDate()
  if (view === 'best') {
    fromDate = moment().subtract(30, 'days').toDate()
  } else if (view === 'worst') {
    fromDate = moment().subtract(4, 'days').toDate()
  } else if (view === 'high-low') {
    fromDate = moment().subtract(7, 'days').toDate()
    ignoredNetworks = ignoreHighLowNetworkUuids
  } else if (view === 'low-high') {
    fromDate = moment().subtract(30, 'days').toDate()
    ignoredNetworks = ignoreHighLowNetworkUuids
  }
  const data = useApiPost<IInsightsV1Statistics>(
    '/insights/v1/statistics',
    {
      fromDate: startOfDay(fromDate),
      toDate: endOfDay(toDate),
      groupBy: tab,
      filters: {
        channelUuids: selectedChannelUuids,
        networkNotUuids: ignoredNetworks,
      },
    },
    [
      tab,
      moment(fromDate).format('YYYY-MM-DD'),
      moment(toDate).format('YYYY-MM-DD'),
      JSON.stringify(selectedChannelUuids),
      view,
    ]
  )
  // const prevData = useApiPost<IInsightsV1Statistics>(
  //   '/insights/v1/statistics',
  //   {
  //     fromDate: startOfDay(prevFromDate),
  //     toDate: endOfDay(prevToDate),
  //     groupBy: tab,
  //     filters: {
  //       channelUuids: selectedChannelUuids,
  //     },
  //   },
  //   [
  //     tab,
  //     moment(prevFromDate).format('YYYY-MM-DD'),
  //     moment(prevToDate).format('YYYY-MM-DD'),
  //     JSON.stringify(selectedChannelUuids),
  //     view,
  //   ]
  // )

  useEffect(() => {
    localStorage.setItem(performanceViewKey, view)
  }, [view])

  const currencyCode = currentUser.currency?.currencyCode || 'DKK'

  const noData =
    isEmpty &&
    totals &&
    totals.totals &&
    totals.totals.clicks?.count === 0 &&
    totals.totals.commission?.value === 0 &&
    totals.totals.conversions?.count === 0 &&
    totals.totals.pageviews?.count === 0

  return (
    <div className="card overview-performance">
      <div className="page-subnav m-b-4">
        <div className="nav">
          <ul>
            <li className={varClass({ 'active': view === 'best' })}>
              <button className="link" onClick={() => setView('best')}>
                Best performing (30 days)
              </button>
            </li>
            <li className={varClass({ 'active': view === 'worst' })}>
              <button className="link" onClick={() => setView('worst')}>
                Worst performing (4 days)
              </button>
            </li>
            <li className={varClass({ 'active': view === 'high-low' })}>
              <button className="link" onClick={() => setView('high-low')}>
                High-low merchants (7 days)
              </button>
            </li>
            <li className={varClass({ 'active': view === 'low-high' })}>
              <button className="link" onClick={() => setView('low-high')}>
                Low-high merchants (30 days)
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="page-subnav">
        <div className="nav p-b-2 m-t--4 m-b-0">
          <ul>
            {map(performanceTabLabels, (key, label) => (
              <li
                key={key}
                className={varClass({
                  'active': tab === key,
                })}
              >
                <button
                  className="link p-y-4"
                  onClick={() => {
                    setTab(key)
                  }}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button
        className="btn btn-bordered btn-report vertical-middle"
        onClick={(event) => {
          if (view === 'worst') {
            navigateTo(event, `/insights/reports?tab=${tab}&crFilter=1`)
          } else if (view === 'high-low') {
            navigateTo(event, `/insights/reports?tab=${tab}`)
          } else if (view === 'low-high') {
            navigateTo(event, `/insights/reports?tab=${tab}`)
          }
        }}
      >
        Show report
        <SVG src="/images/chevron-right.svg" className="m-l-2" />
      </button>
      <Loadable data={data /* && prevData */} placeholder={<PlaceholderTop />}>
        <TopTable
          data={data?.rows || []}
          // prevData={prevData?.rows || []}
          totals={totals?.totals || {}}
          view={view}
          tab={tab}
          currencyCode={currencyCode}
        />
        {noData && (
          <div className="awaiting">
            <SVG src="/images/overview2/awaiting.svg" />
          </div>
        )}
      </Loadable>
    </div>
  )
}

const TopTable = (props) => {
  const { data, prevData, totals, view, tab, currencyCode } = props

  const { sort, dir, toggleSort } = useSortableTable(
    view === 'best'
      ? {
          sort: 'conversionTotalCommission',
          dir: 'desc',
        }
      : view === 'worst'
      ? {
          sort: 'cr',
          dir: 'asc',
        }
      : view === 'high-low'
      ? {
          sort: 'ecr',
          dir: 'desc',
        }
      : view === 'low-high'
      ? {
          sort: 'conversionTotalCommission',
          dir: 'desc',
        }
      : {
          sort: 'conversionTotalCommission',
          dir: 'desc',
        }
  )

  const findPrevItem = (row) => {
    if (!prevData?.length) return undefined
    return prevData.find(
      (prevRow) =>
        (row.name && prevRow.name === row.name) ||
        (row.grp && prevRow.grp === row.grp)
    )
  }

  const headProps = { sort, dir, toggleSort }
  const shownRows = sortTop(data, sort, dir)
    .filter((item: IInsightsV1StatisticsRow) => {
      if (view === 'worst') {
        const networkUuids = [
          ...(item.conversionNetworkUuids || '').split(','),
          ...(item.clickNetworkUuids || '').split(','),
        ].filter((v) => v)
        if (
          networkUuids.length &&
          networkUuids.every((networkUuid) =>
            ignoreWorstNetworkUuids.includes(networkUuid)
          )
        ) {
          return false
        }
        const cr = (item.conversionCount / (item.clickCount || 1)) * 100
        return item.clickCount >= 500 && cr <= 0.8
      } else if (view === 'high-low') {
        if (!item.name && !item.grp) return false
        if (!item.conversionSale || !item.clickCount) {
          return false
        }
        const cr = (item.conversionCount / (item.clickCount || 1)) * 100
        const ecr =
          (item.conversionTotalCommission / (item.conversionSale || 1)) * 100
        return item.clickCount >= 130 && cr >= 0.9 && ecr <= 9.2
      } else if (view === 'low-high') {
        if (!item.name && !item.grp) return false
        if (!item.conversionSale || !item.clickCount) {
          return false
        }
        return item.conversionTotalCommission >= 500 && item.clickCount <= 1000
      } else {
        return true
      }
    })
    .slice(0, view === 'best' ? 25 : 150) as IInsightsV1StatisticsRow[]

  const showPageviews = tab === 'page' || tab === 'channel'
  const showCr = view === 'high-low'
  const showEcr = view === 'high-low'
  const showEpc = false // view === 'low-high'

  useEffect(() => {
    if (!showPageviews && sort === 'pageviewCount') {
      toggleSort('conversionTotalCommission', 'desc')()
    }
  }, [showPageviews])

  return (
    <div className="table-container-overflow">
      <table className="table table-bordered table-sortable table-hoverable">
        <thead>
          <tr>
            <th className="width-xl">{performanceTabLabels[tab]}</th>
            {showPageviews && (
              <TableHead
                value="pageviewCount"
                label="Pageviews"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            <TableHead
              value="clickCount"
              label="Clicks"
              className="width-sm"
              {...headProps}
              desc
            />
            <TableHead
              value="conversionCount"
              label="Conversions"
              className="width-sm"
              {...headProps}
              desc
            />
            {showCr && (
              <TableHead
                value="cr"
                label="CR"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            {showEcr && (
              <TableHead
                value="ecr"
                label="ECR"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            {showEpc && (
              <TableHead
                value="epc"
                label="EPC (4 days / 30 days)"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            <TableHead
              value="conversionTotalCommission"
              label="Revenue"
              className="width-md"
              {...headProps}
              desc
            />
          </tr>
        </thead>
        <tbody>
          {map(shownRows, (item, index) => (
            <TopItem
              key={item[`${tab}Uuid`] || index}
              item={item}
              prevItem={findPrevItem(item)}
              sort={sort}
              totals={totals}
              view={view}
              tab={tab}
              showPageviews={showPageviews}
              showCr={showCr}
              showEcr={showEcr}
              showEpc={showEpc}
              currencyCode={currencyCode}
            />
          ))}
          {shownRows?.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center text-light text-bolder">
                No {performanceTabLabels[tab].toLowerCase()}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Tooltip
        id="overview2-performance-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownRows]}
      />
    </div>
  )
}

const TopItem = (props) => {
  const {
    item,
    prevItem,
    sort,
    totals,
    view,
    tab,
    showPageviews,
    showCr,
    showEcr,
    showEpc,
    currencyCode,
  } = props

  return (
    <tr>
      <td>
        <div className="row row-center row-space-between">
          <WithTooltip
            className="top-value-name name text-dark text-bold"
            label={toUnicode(item.name || item.grp || 'Unknown')}
            tooltipId="overview2-performance-tooltip"
          />
          <div className="row row-center row-narrow row-nowrap">
            {/*
            view === 'slow' && (
              <button
                className="link link-new-lighter link-filter"
                onClick={async (event) => {
                  navigateTo(
                    event,
                    `/insights/reports?tab=${tab}&ecrFilter=1&search=${encodeURIComponent(
                      item.name || item.grp
                    )}`
                  )
                }}
              >
                <SVG
                  width={16}
                  height={16}
                  src="/images/aside-statistics.svg"
                  className="vertical-middle"
                />
              </button>
            )
            */}
            {tab === 'name' && (
              <button
                className="link link-new-lighter link-filter"
                onClick={async (event) => {
                  const response = await getRequest(
                    `/advs/v1/uuid/${item.name || item.grp}`
                  )
                  if (response.data) {
                    navigateTo(event, `/merchants/${response.data}`)
                  }
                }}
              >
                <SVG
                  src="/images/icon-details.svg"
                  className="vertical-middle"
                />
              </button>
            )}
          </div>
        </div>
      </td>
      {showPageviews && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'pageviewCount',
          })}
        >
          <TopValue value={formatStat(item.pageviewCount)} color="red" />
          <TopBar
            show={sort === 'pageviewCount'}
            value={item.pageviewsCount}
            max={totals.pageviews.count}
            color="red"
          />
        </td>
      )}
      <td
        className={varClass({
          'sort-hightlight': sort === 'clickCount',
        })}
      >
        <TopValue value={formatStat(item.clickCount)} color="orange" />
        <TopBar
          show={sort === 'clickCount'}
          value={item.clickCount}
          max={totals.clicks?.count}
          color="orange"
        />
      </td>
      <td
        className={varClass({
          'sort-hightlight': sort === 'conversionCount',
        })}
      >
        <TopValue value={formatStat(item.conversionCount)} color="blue" />
        <TopBar
          show={sort === 'conversionCount'}
          value={item.conversionCount}
          max={totals.conversions?.count}
          color="blue"
        />
      </td>
      {showCr && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'cr',
          })}
        >
          <div className="row row-narrow row-nowrap">
            <TopValue
              value={formatStat(
                (item.conversionCount / (item.clickCount || 1)) * 100,
                'percent2'
              )}
              color="blue"
            />
            {prevItem && (
              <>
                <span>/</span>
                <TopValue
                  value={formatStat(
                    (item.conversionCount / (item.clickCount || 1)) * 100,
                    'percent2'
                  )}
                  color="light-blue"
                />
              </>
            )}
          </div>
        </td>
      )}
      {showEcr && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'ecr',
          })}
        >
          <div className="row row-narrow row-nowrap">
            <TopValue
              value={formatStat(
                (item.conversionTotalCommission / (item.conversionSale || 1)) *
                  100,
                'percent2'
              )}
              color="blue"
            />
            {prevItem && (
              <>
                <span>/</span>
                <TopValue
                  value={formatStat(
                    (prevItem.conversionTotalCommission /
                      (prevItem.conversionSale || 1)) *
                      100,
                    'percent2'
                  )}
                  color="light-blue"
                />
              </>
            )}
          </div>
        </td>
      )}
      {showEpc && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'epc',
          })}
        >
          <div className="row row-narrow row-nowrap">
            <TopValue
              value={formatStat(
                item.conversionTotalCommission / (item.clickCount || 1),
                currencyCode
              )}
              color="blue"
            />
            {prevItem && (
              <>
                <span>/</span>
                <TopValue
                  value={formatStat(
                    prevItem.conversionTotalCommission /
                      (prevItem.clickCount || 1),
                    currencyCode
                  )}
                  color="light-blue"
                />
              </>
            )}
          </div>
        </td>
      )}
      <td
        className={varClass({
          'sort-hightlight': sort === 'conversionTotalCommission',
        })}
      >
        <div className="text-right text-nowrap">
          <TopValue
            value={formatCurrency(
              item.conversionTotalCommission,
              item.currencyCode || currencyCode
            )}
            color="green"
          />
        </div>
        <TopBar
          show={sort === 'conversionTotalCommission'}
          value={item.conversionTotalCommission}
          max={totals.commission?.value}
          color="green"
        />
      </td>
    </tr>
  )
}

const TopValue = (props) => {
  const { value, color } = props

  return (
    <div className={`top-value top-value-${color} text-nowrap`}>{value}</div>
  )
}

const TopBar = (props) => {
  const { show, value, max, color } = props

  if (!show) {
    return null
  }

  return (
    <div className={`top-bar top-bar-${color}`}>
      <div style={{ width: `${(value / max) * 100}%` }} />
    </div>
  )
}
