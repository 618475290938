import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  AsyncSelect,
  capitalize,
  debounce,
  map,
  responseError,
  useFormSubmit,
  useModal,
} from '../shared'
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../../services'
import { formats, formatExtensions } from './index'

export const FeedFormModal = (props) => {
  const { feed, reloadFeeds, showSnackbar } = props

  const isNew = !feed

  const { showModal, hideModal } = useModal()

  const form = useForm({
    defaultValues: {
      name: feed?.name || '',
      domain: feed?.domain || '',
      filename: feed?.filename || '',
      country: feed?.country || '',
      currency: feed?.currency || '',
      feedUrl: feed?.feedUrl || '',
      feedFormat: feed?.feedFormat?.toLowerCase() || '',
      priceStepCount: feed?.priceStepCount ?? 5,
      excludeOutOfStock: !!(feed?.isActive ?? 1),
      isActive: !!(feed?.isActive ?? 1),
    },
  })

  const { register, handleSubmit, setValue } = form

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    values.priceStepCount = parseInt(values.priceStepCount)
    values.excludeOutOfStock = values.excludeOutOfStock ? '1' : '0'
    values.isActive = values.isActive ? '1' : '0'

    const response = isNew
      ? await postRequest('/feeds/v1/xml', values)
      : await putRequest(`/feeds/v1/xml/${feed.uuid}`, values)

    if (response) {
      if (response.code === 200 && response.data.success) {
        hideModal()
        reloadFeeds()
      } else {
        showSnackbar(responseError(response), { type: 'alert' })
      }
    }
  })

  const handleDelete = async () => {
    if (!feed) return
    await deleteRequest(`/feeds/v1/xml/${feed.uuid}`)
    hideModal()
    reloadFeeds()
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title row row-space-between">
          {isNew ? 'Create' : 'Edit'} feed
        </div>
      </div>

      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="m-b-20">
            <div className="control w-100">
              <label>Feed source</label>
              <FeedSourceSelect
                onSelect={(feed) => {
                  setValue('name', feed?.feedName || '')
                  setValue('domain', feed?.feedAdvertiserDomain || '')
                  setValue('filename', getFilename(feed?.feedFormat))
                  setValue('country', feed?.feedCountryCode || '')
                  setValue('currency', feed?.feedCurrencyCode || '')
                  setValue('feedUrl', feed?.feedUrl || '')
                  setValue('feedFormat', feed?.feedFormat?.toLowerCase() || '')
                }}
              />
            </div>
          </div>
          <div className="m-b-20">
            <div className="control w-100">
              <label>Vendor name</label>
              <input
                type="text"
                className="w-100"
                {...register('name', { required: true })}
              />
            </div>
          </div>
          <div className="m-b-20">
            <div className="control w-100">
              <label>Vendor domain</label>
              <input
                type="text"
                className="w-100"
                {...register('domain', { required: true })}
              />
            </div>
          </div>
          <div className="m-b-20">
            <div className="control w-100">
              <label>File name in Google Merchant Center</label>
              <input
                type="text"
                className="w-100"
                {...register('filename', { required: true })}
              />
            </div>
          </div>
          <div className="m-b-20 row">
            <div className="flex-1">
              <div className="control w-100">
                <label>Default country code</label>
                <input
                  type="text"
                  className="w-100"
                  {...register('country', { required: true })}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="control w-100">
                <label>Default currency code</label>
                <input
                  type="text"
                  className="w-100"
                  {...register('currency', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="m-b-20 row">
            <div className="flex-1">
              <div className="control w-100">
                <label>Feed source URL</label>
                <input
                  type="text"
                  className="w-100"
                  {...register('feedUrl', { required: true })}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="control control-select w-100">
                <label>Feed source format</label>
                <select
                  className="w-100"
                  {...register('feedFormat', { required: true })}
                >
                  {map(formats, (value, label) => (
                    <option key={value} value={value}>
                      {label} ({formatExtensions[value]})
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <hr className="m-y-20" />
          <div className="m-b-20">
            <div className="control w-50">
              <label>Price intervals</label>
              <input
                type="text"
                className="w-100"
                {...register('priceStepCount', {
                  required: true,
                  pattern: {
                    value: /^\d+$/,
                    message: 'Not a number',
                  },
                })}
              />
            </div>
          </div>
          <div className="m-b-20 row">
            <div className="flex-1">
              <div className="control control-checkbox w-100">
                <input type="checkbox" {...register('excludeOutOfStock')} />
                <label>Exclude "out of stock" products</label>
              </div>
            </div>
            <div className="flex-1">
              <div className="control control-checkbox w-100">
                <input type="checkbox" {...register('isActive')} />
                <label>Keep XML feed up-to-date</label>
              </div>
            </div>
          </div>

          <div className="row row-space-between">
            {isNew ? (
              <div />
            ) : (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  showModal(
                    <DeleteModal onClose={hideModal} onSubmit={handleDelete} />
                  )
                }}
                disabled={submitting}
              >
                Delete
              </button>
            )}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={submitting}
            >
              {isNew ? 'Create' : 'Update'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const FeedSourceSelect = (props) => {
  const { onSelect } = props

  const searchRef = useRef()
  const onLoadMore = useRef<(arr: any[]) => void>()

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(null)
  const changeSearch = useMemo(() => debounce(setSearch, 600), [setSearch])
  useEffect(() => {
    const callback = async () => {
      if (onLoadMore.current) {
        if (search) {
          const response = await getRequest('/feeds/v1/feeds', {
            search: search,
          })
          onLoadMore.current(
            response.data.map((feed) => ({
              label:
                [feed.feedName, feed.feedAdvertiserDomain, feed.feedCountryCode]
                  .filter(Boolean)
                  .join(' - ') + ` (${feed.feedFormat})`,
              value: feed.feedUuid,
              feed,
            }))
          )
        } else {
          onLoadMore.current([])
        }
      }
    }
    callback()
  }, [search])

  return (
    <AsyncSelect
      inputRef={searchRef}
      placeholder="Select feed source"
      loadOptions={(inputValue, callback) => {
        onLoadMore.current = callback
        changeSearch(inputValue)
      }}
      value={selected}
      onChange={(value) => {
        setSelected(value)
        onSelect(value?.feed)
      }}
      cacheOptions
    />
  )
}

const getFilename = (format: string): string => {
  if (!format) return ''
  const canonical = format.toLowerCase()
  if (formats[canonical]) {
    return formats[canonical] + '.xml'
  } else {
    return capitalize(canonical)
  }
}

const DeleteModal = (props) => {
  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete feed</div>
      </div>
      <div className="card-body">
        <p className="m-t-0 m-b-6">Are you sure?</p>
        <div className="text-right">
          <button className="btn" onClick={props.onClose}>
            Cancel
          </button>
          <button className="btn btn-danger m-l-2" onClick={props.onSubmit}>
            Delete
          </button>
        </div>
      </div>
    </>
  )
}
