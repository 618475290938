import React, { useMemo } from 'react'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import {
  useApiPost,
  useSortableTable,
  TableHead,
  map,
  newTableSort,
  navigateTo,
} from '../../shared'

const sortClosed = newTableSort((a: any, b: any, key) => {
  if (key === 'trackerChannelUuids') {
    return [a.trackerChannelUuids.length, b.trackerChannelUuids.length]
  }
})

export const ClosedTable = (props) => {
  const { channels, networks, advertisers } = props

  const data = useApiPost<any[]>('/insights/v1/closed', {
    fromDate: moment().subtract(15, 'days').toDate(),
    toDate: moment().subtract(1, 'day').toDate(),
    filters: {
      minClickCount: 50,
    },
  })

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'clickCount',
    dir: 'desc',
  })

  const shownRows = sortClosed(data, sort, dir)

  const headProps = { sort, dir, toggleSort }
  const itemProps = { channels, networks, advertisers }

  return (
    <div className="card overview-performance m-t-20">
      <div className="card-body p-t-1">
        <div className="row row-space-between m-b-5">
          <div className="text-dark text-bolder">Recently closed campaigns</div>
        </div>

        <div className="table-container-overflow">
          <table className="table table-bordered table-sortable table-hoverable">
            <thead>
              <tr>
                <TableHead
                  value="trackerProgramId"
                  label="Campaign"
                  {...headProps}
                />
                <TableHead
                  value="trackerNetworkUuid"
                  label="Network"
                  {...headProps}
                />
                <TableHead
                  value="trackerChannelUuids"
                  label="Sites"
                  {...headProps}
                />
                <TableHead
                  value="clickCount"
                  label="Clicks (14 days)"
                  {...headProps}
                  desc
                />
              </tr>
            </thead>
            <tbody>
              {map(shownRows, (item) => (
                <ClosedItem
                  key={item.trackerProgramId}
                  item={item}
                  {...itemProps}
                />
              ))}
              {data?.length === 0 && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const ClosedItem = (props) => {
  const { item, channels, networks, advertisers } = props

  const programId = item.trackerProgramId.replace(/^\w+-/, '')

  const [trackerAdvertiser, trackerChannels, trackerNetwork] = useMemo(() => {
    const trackerAdvertiser = advertisers?.find(
      (advertiser) => advertiser.advertiserUuid === item.trackerAdvertiserUuid
    )
    const trackerChannels = channels?.filter((channel) =>
      item.trackerChannelUuids.includes(channel.channelUuid)
    )
    const trackerNetwork = networks?.find(
      (network) => network.networkUuid === item.trackerNetworkUuid
    )
    return [trackerAdvertiser, trackerChannels, trackerNetwork]
  }, [item, channels, networks, advertisers])

  return (
    <tr>
      <td>
        <div className="row row-center row-space-between row-nowrap">
          <div className="flex-1 text-nowrap">
            {trackerAdvertiser ? (
              <>
                <div className="text-dark text-bold m-b-1">
                  {trackerAdvertiser.advertiserDomain}
                </div>
                <div className="text-light">{programId}</div>
              </>
            ) : (
              <div className="text-dark text-bold">{programId}</div>
            )}
          </div>

          <button
            className="link link-new-lighter link-filter"
            onClick={async (event) => {
              navigateTo(event, `/merchants/${item.trackerAdvertiserUuid}`)
            }}
          >
            <SVG src="/images/icon-details.svg" className="vertical-middle" />
          </button>
        </div>
      </td>
      <td>
        {trackerNetwork && (
          <div
            key={trackerNetwork.networkUuid}
            className="badge text-smaller badge-hoverable text-bold text-nowrap"
            onClick={(event) => {
              navigateTo(event, `/networks/${trackerNetwork.networkUuid}`)
            }}
          >
            {trackerNetwork.networkName}
          </div>
        )}
      </td>
      <td>
        {map(trackerChannels, (channel) => (
          <div
            key={channel.channelUuid}
            className="badge text-smaller badge-hoverable text-bold text-nowrap m-1"
          >
            {channel.channelName}
          </div>
        ))}
      </td>
      <td>
        <div className="top-value top-value-orange text-nowrap">
          {item.clickCount}
        </div>
      </td>
    </tr>
  )
}
