import React, { createContext, useContext } from 'react'

type Setter = <T>(newValue?: T) => void
interface ContextState {
  filters: {
    key: string
    value: string
    label: string
  }[]
  setFilters: Setter
  datePickerState: any
  advertisers: {
    advertiserUuid: string
    advertiserDomain: string
  }[]
  channels: {
    channelUuid: string
    channelName: string
    channelDomain: string
    channelFaviconUrl: string
    channelType: string
  }[]
  networks: {
    networkUuid: string
    networkName: string
    networkLogoUrl: string
  }[]
  users: {
    userUuid: string
    userFirstname: string
    userLastname: string
  }[]
  assignees: {
    assigneeAdvertiserUuid: string
    assigneeAdvertiserDomain: string
    assigneeUserFirstname: string
    assigneeUserLastname: string
  }[]
  statuses: {
    advertiserStatusAdvertiserUuid: string
    advertiserStatusAdvertiserDomain: string
    advertiserStatusType: string
    advertiserStatusValue: string
  }[]
  setAssignees: Setter
  search: string
  setSearch: Setter
  data: {
    rows: any
    totals: any
  }
  prevData: {
    rows: any
    totals: any
  }
  favorites: {
    favoriteUuid?: string
    favoriteGroupBy?: string
    favoriteGroupName?: string
  }[]
  setFavorites: Setter
  refreshFavorites: Setter
  group: string
  setGroup: Setter
  columns: string[]
  setColumns: Setter
  availableColumns: string[]
  crFilter: boolean
  setCrFilter: Setter
  ecrFilter: boolean
  setEcrFilter: Setter
  favFilter: boolean
  setFavFilter: Setter
  trafficFilter: boolean
  setTrafficFilter: Setter
  showGraph: boolean
  setShowGraph: Setter
  graphHeight: number
  setGraphHeight: Setter
  graphColumns: string[]
  setGraphColumns: Setter
  currencyCode: string
  setSelectedGroupByTime: Setter
  selectedGroupByTime: string
}

export const statisticsContext = createContext({})

export const useStatistics = (): ContextState =>
  useContext(statisticsContext) as ContextState

export const StatisticsContextProvider = statisticsContext.Provider
