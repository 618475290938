import React, { useMemo, useState } from 'react'
import SVG from 'react-inlinesvg'
import { Placeholder } from './Placeholder'
import {
  formatStat,
  Header,
  isBlank,
  Loadable,
  map,
  navigateTo,
  newTableSort,
  NoDataDiv,
  TableHead,
  Tooltip,
  useApiGet,
  useSortableTable,
  varClass,
} from '../../shared'

const opportunitySort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'channel') {
    return [a.channels.length, b.channels.length]
  } else if (key === 'network') {
    return [a.networks.length, b.networks.length]
  } else if (key === 'clickCount') {
    return [a.stats.clickCount, b.stats.clickCount]
  } else if (key === 'conversionTotalCommission') {
    return [
      a.stats.conversionTotalCommission,
      b.stats.conversionTotalCommission,
    ]
  } else if (key === 'epc') {
    return [
      a.stats.clickCount > 0
        ? a.stats.conversionTotalCommission / a.stats.clickCount
        : 0,
      b.stats.clickCount > 0
        ? b.stats.conversionTotalCommission / b.stats.clickCount
        : 0,
    ]
  }
})

export const Splittests = (props) => {
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/list')

  const data = useApiGet('/discovers/v1/splittests/opportunities')

  return (
    <>
      <Header label="Split test opportunities" />
      <Loadable
        data={data && channels && networks}
        placeholder={<Placeholder />}
      >
        <Content data={data} channels={channels} networks={networks} />
        <Tooltip type="dark" place="bottom" />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const data = useMemo(() => {
    const { channels, networks, data } = props
    if (!channels || !networks || !data) return []

    const channelsObject = channels.reduce((result, channel) => {
      result[channel.channelUuid] = channel
      return result
    }, {})
    const networksObject = networks.reduce((result, network) => {
      result[network.networkUuid] = network
      return result
    }, {})

    return data.opportunities
      .filter((row) => !!row.stats.name)
      .map((row) => ({
        ...row,
        domain: row.stats.name,
        channels: row.channelUuids
          .map((channelUuid) => channelsObject[channelUuid])
          .filter(Boolean)
          .sort((a, b) => a.channelName.localeCompare(b.channelName)),
        networks: row.networkUuids
          .map((networkUuid) => networksObject[networkUuid])
          .filter(Boolean)
          .sort((a, b) => a.networkName.localeCompare(b.networkName)),
      }))
  }, [props.channels, props.networks, props.data])

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'domain',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const shownData = opportunitySort(data, sort, dir)

  if (isBlank(data)) {
    return (
      <NoDataDiv
        description="No new opportunities found yet."
        className="p-y-50"
      />
    )
  }

  return (
    <div className="card">
      <div className="text-dark text-bold m-b-5">
        Split test opportunities (30 days)
      </div>
      <div className="card-body card-body-fill">
        <div className="table-container-overflow">
          <table className="table table-bordered table-sortable">
            <thead>
              <tr>
                <TableHead value="domain" label="Domain" {...headProps} />
                <TableHead value="clickCount" label="Clicks" {...headProps} />
                <TableHead
                  value="conversionTotalCommission"
                  label="Commission"
                  {...headProps}
                />
                <TableHead value="epc" label="EPC" {...headProps} />
                <TableHead
                  value="network"
                  label="Available networks"
                  {...headProps}
                />
                <TableHead value="channel" label="Sites" {...headProps} />
              </tr>
            </thead>
            <tbody>
              {map(shownData, (item) => (
                <TableItem key={item.domain} item={item} sort={sort} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TableItem = (props) => {
  const { item, sort } = props

  const [channelsExpanded, setChannelsExpanded] = useState(false)
  const canExpandChannels = item.channels?.length > 4

  const shownChannels = useMemo(
    () => (channelsExpanded ? item.channels : item.channels?.slice(0, 3)),
    [channelsExpanded, item?.channels]
  )

  return (
    <tr>
      <td
        className={varClass({
          'relative w-30 vertical-middle': true,
          'text-dark sort-highlight': sort === 'domain',
        })}
      >
        <div className="row row-narrow row-nowrap row-space-between">
          <div className="text-dark text-bold">{item.domain}</div>
          <button
            className="link link-new-lighter link-filter"
            onClick={(event) => {
              navigateTo(event, `/merchants/${item.advertiserUuid}`)
            }}
          >
            <SVG
              src="/images/icon-details.svg"
              className="arrow vertical-middle"
            />
          </button>
        </div>
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'clickCount',
        })}
      >
        <div className="value-badge value-badge-clickCount text-nowrap">
          {formatStat(item.stats.clickCount || 0)}
        </div>
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'conversionTotalCommission',
        })}
      >
        <div className="value-badge value-badge-conversionCommission text-nowrap">
          {formatStat(item.stats.conversionTotalCommission || 0, 'DKK', true)}
        </div>
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'epc',
        })}
      >
        <div className="text-nowrap">
          {formatStat(
            item.stats.clickCount > 0
              ? item.stats.conversionTotalCommission / item.stats.clickCount
              : 0,
            'DKK',
            true
          )}
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'network',
        })}
      >
        {map(item.networks, (network) => (
          <span
            key={network.networkUuid}
            className="badge badge-hoverable text-smaller p-y-05 m-05"
            onClick={(event) => {
              navigateTo(event, `/networks/${network.networkUuid}`)
            }}
          >
            {network.networkName}
          </span>
        ))}
      </td>
      <td
        className={varClass({
          'overflow-hide': true,
          'sort-highlight': sort === 'channel',
        })}
      >
        {map(shownChannels, (channel) => (
          <span
            key={channel.channelUuid}
            className="badge badge-hoverable text-smaller p-y-05 m-05"
            onClick={(event) => {
              navigateTo(event, `/sites/${channel.channelUuid}`)
            }}
          >
            {channel.channelName || channel.channelDomain}
          </span>
        ))}
        {canExpandChannels && (
          <>
            {channelsExpanded ? (
              <div
                className="badge badge-hoverable text-smaller text-nowrap m-05"
                onClick={(event) => {
                  setChannelsExpanded(false)
                }}
              >
                show less
              </div>
            ) : (
              <div
                className="badge badge-hoverable text-smaller text-nowrap m-05"
                onClick={(event) => {
                  setChannelsExpanded(true)
                }}
              >
                show more
              </div>
            )}
          </>
        )}
      </td>
    </tr>
  )
}
