import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import moment from 'moment'
import {
  Header,
  Loadable,
  useApiMore,
  useSortableTable,
  map,
  isBlank,
  varClass,
  SearchSubmit,
  useModal,
  useSnackbar,
  SmallLoadable,
} from '../shared'
import { MappingModal } from './MappingModal'

const perPage = 50

export const Creators = (props) => {
  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [tab, setTab] = useState<'creators' | 'advertisers'>(
    location.hash?.includes?.('advertisers') ? 'advertisers' : 'creators'
  )
  useEffect(() => {
    window.history.pushState('', '', `#${tab}`)
  }, [tab])

  const [search, setSearch] = useState('')

  const [selectedLabel, setSelectedLabel] = useState('')
  const [selectedCreatorId, setSelectedCreatorId] = useState('')
  const [selectedAdvertiserUuid, setSelectedAdvertiserUuid] = useState('')
  const onSelect = (id: string, label: string) => {
    if (tab === 'creators') {
      if (!selectedAdvertiserUuid) {
        setSelectedCreatorId(id)
        setSelectedLabel(label)
        setSearch('')
        setTab('advertisers')
      } else {
        showModal(
          <MappingModal
            creatorId={id}
            advertiserUuid={selectedAdvertiserUuid}
            showSnackbar={showSnackbar}
          />,
          {
            cardClassName: 'card-full',
          }
        )
      }
    } else if (tab === 'advertisers') {
      if (!selectedCreatorId) {
        setSelectedAdvertiserUuid(id)
        setSelectedLabel(label)
        setSearch('')
        setTab('creators')
      } else {
        showModal(
          <MappingModal
            creatorId={selectedCreatorId}
            advertiserUuid={id}
            showSnackbar={showSnackbar}
          />,
          {
            cardClassName: 'card-full',
          }
        )
      }
    }
  }

  return (
    <>
      <Header label="Creators" />

      <div className="page-nav m-b-4">
        <div className="row row-space-between">
          <SearchSubmit
            value={search}
            setValue={setSearch}
            className="m-0"
            inputClassName="min-w-40vw"
            highlight={!!search}
          />
          {selectedCreatorId || selectedAdvertiserUuid ? (
            <div className="nav-filters">
              <div className="nav-filter">
                <div className="nav-filter-button p-r-3">
                  {selectedLabel}
                  <button
                    className="nav-filter-remove"
                    onClick={() => {
                      setSelectedLabel('')
                      setSelectedCreatorId('')
                      setSelectedAdvertiserUuid('')
                    }}
                  >
                    <SVG src="/images/insights/icon-trash.svg" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>

      <div className="card">
        <div className="page-subnav">
          <div className="nav">
            <ul>
              {map(
                {
                  'creators': 'Creators',
                  'advertisers': 'Merchants',
                },
                (value, label) => (
                  <li key={value} className={tab === value ? 'active' : ''}>
                    <button className="link" onClick={() => setTab(value)}>
                      {label}
                    </button>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>

        <div className="card-divider" />

        <div className="card-body card-body-fill p-t-0">
          {tab === 'creators' && (
            <CreatorTable
              search={search}
              onSelect={onSelect}
              selectedAdvertiserUuid={selectedAdvertiserUuid}
            />
          )}
          {tab === 'advertisers' && (
            <AdvertiserTable
              search={search}
              onSelect={onSelect}
              selectedCreatorId={selectedCreatorId}
            />
          )}
        </div>
      </div>
    </>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort, className } = props

  const isSortable = !!toggleSort

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
        [className]: !!className,
      })}
      onClick={toggleSort?.(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {isSortable ? (
          <>
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </>
        ) : null}
      </span>
    </th>
  )
}

const CreatorTable = (props) => {
  const { search, onSelect, selectedAdvertiserUuid } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'creatorName',
    dir: 'asc',
  })

  const { data, loading, loaded, hasMore, loadMore } = useApiMore(
    '/creators/v1/creators',
    (offset: number) => {
      return {
        advertiserUuid: selectedAdvertiserUuid,
        search,
        offset,
        limit: perPage,
        orderBy: sort,
        orderDirection: dir,
      }
    },
    {
      perPage,
      resetDeps: [sort, dir, search, selectedAdvertiserUuid],
    }
  )

  if (!loaded) {
    return <Loadable data={loaded} />
  }

  const loadMoreOnScroll = (event) => {
    if (loaded && hasMore && !loading) {
      const element = event.target
      const bottom = element.scrollHeight - element.offsetHeight - 100
      const isScrolledToBottom = element.scrollTop >= bottom
      if (isScrolledToBottom) {
        loadMore()
      }
    }
  }

  const headProps = { sort, dir, toggleSort }

  return (
    <div
      className="table-container-overflow"
      onScroll={loadMoreOnScroll}
      style={{ maxHeight: 'calc(100vh - 250px)' }}
    >
      <table className="table table-bordered table-sortable table-hoverable">
        <thead className="table-sticky">
          <tr>
            <TableHead value="creatorName" label="Creator" {...headProps} />
            <TableHead value="creatorId" label="Creator ID" {...headProps} />
            <TableHead
              value="creatorCreatedDatetime"
              label="Created at"
              {...headProps}
            />
            <TableHead
              value="creatorUpdatedDatetime"
              label="Updated at"
              {...headProps}
            />
          </tr>
        </thead>
        <tbody>
          {map(data, (creator) => (
            <CreatorTableItem
              key={creator.creatorId}
              creator={creator}
              onSelect={onSelect}
              isGoto={!!selectedAdvertiserUuid}
            />
          ))}
          {isBlank(data) && !loading && (
            <tr>
              <td colSpan={10} className="text-light text-bolder text-center">
                Nothing found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <SmallLoadable loaded={!loading} className="m-y-4" />
    </div>
  )
}

const CreatorTableItem = (props) => {
  const { creator, onSelect, isGoto } = props

  return (
    <tr>
      <td>
        <button
          className="name"
          onClick={() => {
            onSelect(creator.creatorId, creator.creatorName)
          }}
        >
          <div>
            <div>{decodeURIComponent(creator.creatorName)}</div>
            <div>{decodeURIComponent(creator.creatorSlug)}</div>
          </div>
          {isGoto ? (
            <SVG src="/images/icon-details.svg" className="arrow" />
          ) : (
            <SVG src="/images/icon-filter.svg" className="arrow" />
          )}
        </button>
      </td>
      <td>{creator.creatorId}</td>
      <td>
        {moment(creator.creatorCreatedDatetime).format('YYYY-MM-DD HH:mm:ss')}
      </td>
      <td>
        {moment(creator.creatorUpdatedDatetime).format('YYYY-MM-DD HH:mm:ss')}
      </td>
    </tr>
  )
}

const AdvertiserTable = (props) => {
  const { search, onSelect, selectedCreatorId } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'advertiserDomain',
    dir: 'asc',
  })

  const { data, loading, loaded, hasMore, loadMore } = useApiMore(
    '/creators/v1/advertisers',
    (offset: number) => {
      return {
        creatorId: selectedCreatorId,
        search,
        offset,
        limit: perPage,
        orderBy: sort,
        orderDirection: dir,
      }
    },
    {
      perPage,
      resetDeps: [sort, dir, search, selectedCreatorId],
    }
  )

  if (!loaded) {
    return <Loadable data={loaded} />
  }

  const loadMoreOnScroll = (event) => {
    if (loaded && hasMore && !loading) {
      const element = event.target
      const bottom = element.scrollHeight - element.offsetHeight - 100
      const isScrolledToBottom = element.scrollTop >= bottom
      if (isScrolledToBottom) {
        loadMore()
      }
    }
  }

  const headProps = { sort, dir, toggleSort }

  return (
    <div
      className="table-container-overflow"
      onScroll={loadMoreOnScroll}
      style={{ maxHeight: 'calc(100vh - 250px)' }}
    >
      <table className="table table-bordered table-sortable table-hoverable">
        <thead className="table-sticky">
          <tr>
            <TableHead
              value="advertiserDomain"
              label="Merchant"
              {...headProps}
            />
            <TableHead value="creatorCount" label="Creators" />
            <TableHead value="creatorPendingCount" label="Pending" />
            <TableHead value="creatorApprovedCount" label="Approved" />
            <TableHead value="creatorDeclinedCount" label="Declined" />
          </tr>
        </thead>
        <tbody>
          {map(data, (advertiser) => (
            <AdvertiserTableItem
              key={advertiser.advertiserDomain}
              advertiser={advertiser}
              onSelect={onSelect}
              isGoto={!!selectedCreatorId}
            />
          ))}
          {isBlank(data) && !loading && (
            <tr>
              <td colSpan={10} className="text-light text-bolder text-center">
                Nothing found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <SmallLoadable loaded={!loading} className="m-y-4" />
    </div>
  )
}

const AdvertiserTableItem = (props) => {
  const { advertiser, onSelect, isGoto } = props

  return (
    <tr>
      <td
        className={varClass({
          'text-italic': !advertiser.advertiserDomain,
        })}
      >
        <button
          className="name"
          onClick={() => {
            onSelect(advertiser.advertiserUuid, advertiser.advertiserDomain)
          }}
        >
          {advertiser.advertiserDomain || 'Unknown'}
          {isGoto ? (
            <SVG src="/images/icon-details.svg" className="arrow" />
          ) : (
            <SVG src="/images/icon-filter.svg" className="arrow" />
          )}
        </button>
      </td>
      <td>{advertiser.creatorCount || 0}</td>
      <td>{advertiser.creatorPendingCount || 0}</td>
      <td>{advertiser.creatorApprovedCount || 0}</td>
      <td>{advertiser.creatorDeclinedCount || 0}</td>
    </tr>
  )
}
