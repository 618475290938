import React from 'react'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import {
  useApiPost,
  useSortableTable,
  TableHead,
  map,
  newTableSort,
  navigateTo,
  formatStat,
  formatDiff,
  varClass,
} from '../../shared'

const sortDrop = newTableSort((a: any, b: any, key) => {
  if (key === 'drop') {
    const diffA = a.conversionTotalCommission / a.prev.conversionTotalCommission
    const diffB = b.conversionTotalCommission / b.prev.conversionTotalCommission
    return [diffB, diffA]
  }
})

export const CommissionDropTable = (props) => {
  const data = useApiPost<{ rows: any[] }>('/insights/v1/reducing', {
    fromDate: moment().subtract(15, 'days').toDate(),
    toDate: moment().subtract(1, 'day').toDate(),
    prevFromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    prevToDate: moment().subtract(16, 'days').format('YYYY-MM-DD'),
  })

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'drop',
    dir: 'desc',
  })

  const shownRows = sortDrop(data?.rows, sort, dir)

  const headProps = { sort, dir, toggleSort }

  return (
    <div className="card overview-performance m-t-20">
      <div className="card-body p-t-1">
        <div className="row row-space-between m-b-5">
          <div className="text-dark text-bolder">
            Drop in commission (14 days)
          </div>
        </div>

        <div className="table-container-overflow">
          <table className="table table-bordered table-sortable table-hoverable">
            <thead>
              <tr>
                <TableHead
                  value="name"
                  label="Advertiser"
                  className="w-100"
                  {...headProps}
                />
                <TableHead
                  value="clickCount"
                  label="Clicks"
                  {...headProps}
                  desc
                />
                <TableHead
                  value="conversionCount"
                  label="Conversions"
                  {...headProps}
                  desc
                />
                <TableHead
                  value="conversionTotalCommission"
                  label="Commission"
                  {...headProps}
                  desc
                />
                <TableHead value="drop" label="Com. drop" {...headProps} desc />
              </tr>
            </thead>
            <tbody>
              {map(shownRows, (item) => (
                <TableItem key={item.grp} item={item} sort={sort} />
              ))}
              {data?.rows?.length === 0 && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TableItem = (props) => {
  const { item, sort } = props

  return (
    <tr>
      <td
        className={varClass({
          'sort-highlight': sort === 'name',
        })}
      >
        <div className="row row-center row-space-between row-nowrap">
          <div className="flex-1 text-nowrap">
            <div className="text-dark text-bold m-b-1">{item.name}</div>
          </div>

          <button
            className="link link-new-lighter link-filter"
            onClick={async (event) => {
              navigateTo(event, `/merchants/${item.grp}`)
            }}
          >
            <SVG src="/images/icon-details.svg" className="vertical-middle" />
          </button>
        </div>
      </td>
      <td
        className={varClass({
          'text-right text-nowrap': true,
          'sort-highlight': sort === 'clickCount',
        })}
      >
        <div className="value-badge value-badge-clickCount m-b-1">
          {formatStat(item.clickCount)}
        </div>
        <br />
        <div className="row row-center row-end row-nowrap m-t-1">
          <div className="text-smallest text-light o-70">
            {formatDiff(item.clickCount, item.prev.clickCount)}
          </div>
          <div className="value-badge value-badge-clickCount value-badge-sm">
            {formatStat(item.prev.clickCount)}
          </div>
        </div>
      </td>
      <td
        className={varClass({
          'text-right text-nowrap': true,
          'sort-highlight': sort === 'conversionCount',
        })}
      >
        <div className="value-badge value-badge-conversionCount m-b-1">
          {formatStat(item.conversionCount)}
        </div>
        <br />
        <div className="row row-center row-end row-nowrap m-t-1">
          <div className="text-smallest text-light o-70">
            {formatDiff(item.conversionCount, item.prev.conversionCount)}
          </div>
          <div className="value-badge value-badge-conversionCount value-badge-sm">
            {formatStat(item.prev.conversionCount)}
          </div>
        </div>
      </td>
      <td
        className={varClass({
          'text-right text-nowrap': true,
          'sort-highlight': sort === 'conversionTotalCommission',
        })}
      >
        <div className="value-badge value-badge-conversionTotalCommission m-b-1">
          {formatStat(item.conversionTotalCommission, 'DKK')}
        </div>
        <br />
        <div className="row row-center row-end row-nowrap m-t-1">
          <div className="text-smallest text-light o-70">
            {formatDiff(
              item.conversionTotalCommission,
              item.prev.conversionTotalCommission
            )}
          </div>
          <div className="value-badge value-badge-conversionTotalCommission value-badge-sm">
            {formatStat(item.prev.conversionTotalCommission, 'DKK')}
          </div>
        </div>
      </td>
      <td
        className={varClass({
          'text-right text-nowrap': true,
          'sort-highlight': sort === 'drop',
        })}
      >
        {formatDiff(
          item.conversionTotalCommission,
          item.prev.conversionTotalCommission
        )}
      </td>
    </tr>
  )
}
