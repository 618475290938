import React, { useState, useEffect, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { toUnicode } from 'punycode'
import { isEqual } from 'lodash'
import moment from 'moment'
import { startOfDay, endOfDay, parse } from 'date-fns'
import { Placeholder } from './Placeholder'
import { TopChart } from './TopChart'
import {
  Header,
  NavFilter,
  DatePicker,
  useDatePickerState,
  Link,
  Trend,
  Tooltip,
  WithTooltip,
  NoDataDiv,
  Loadable,
  SmallLoadable,
  ConversionDrawer,
  ConversionJourneyDrawer,
  useApiGet,
  useApiPost,
  useDrawer,
  useSnackbar,
  useInterval,
  formatFilterRecords,
  useMemoPresent,
  formatStat,
  isBlank,
  map,
  varClass,
  host,
  checkAccountConversions,
} from '../shared'
import { getCurrentUser, isDemo } from '../../services/auth'
import { PerformanceTable } from './components/PerformanceTable'
import { CommissionDropTable } from './components/CommissionDropTable'
import { ClosedTable } from './components/ClosedTable'

const normalizeFilter = (filters) => {
  const result = {}

  for (const filter of filters) {
    if (filter.value) {
      result[filter.key] = filter.value.split(', ')
    }
  }

  return result
}

const formatDateFloor = (
  diff: number,
  factor: number,
  letter: string
): string => `${Math.floor(diff / factor)}${letter}`

const formatDateCeil = (diff: number, factor: number, letter: string): string =>
  `${Math.ceil(diff / factor)}${letter}`

const formatDate = (dateStr: string): string => {
  const now: any = new Date()
  const date: any = parse(
    dateStr + ' +01',
    'yyyy-MM-dd, HH:mm:ss X',
    new Date()
  )
  if (date > now - 10 * 1000) return 'Just now'
  if (date > now - 60 * 1000) return formatDateCeil(now - date, 1000, 's')
  if (date > now - 60 * 60 * 1000)
    return formatDateFloor(now - date, 60 * 1000, 'm')
  if (date > now - 24 * 60 * 60 * 1000)
    return formatDateFloor(now - date, 60 * 60 * 1000, 'h')
  if (date > now - 7 * 24 * 60 * 60 * 1000)
    return formatDateFloor(now - date, 24 * 60 * 60 * 1000, 'd')
  return formatDateFloor(now - date, 7 * 24 * 60 * 60 * 1000, 'w')
}

const parseDevices = (data) => {
  if (!data || !Array.isArray(data)) {
    return {
      desktop: 0,
      mobile: 0,
      tablet: 0,
    }
  } else {
    return {
      desktop:
        data.find((datum) => datum.device === 'Desktop')?.pageviewCount || 0,
      mobile:
        data.find((datum) => datum.device === 'Mobile')?.pageviewCount || 0,
      tablet:
        data.find((datum) => datum.device === 'Tablet')?.pageviewCount || 0,
    }
  }
}

const findNetwork = (networks, networkUuid) =>
  networks.find((network) => network.networkUuid === networkUuid)

const detailName = (value: string): string => {
  if (!value) {
    return ''
  } else if (value === 'heylink.com') {
    return 'Test conversion'
  } else {
    return value
  }
}

let accountActivityMemo = null

export const Overview = (props) => {
  const datePickerState = useDatePickerState()

  const [filters, setFilters] = useState([
    {
      key: 'channelUuids',
      label: '',
      value: '',
    },
  ])
  const filterOptions = normalizeFilter(filters)
  const selectedChannelFilter = filters.find(
    (filter) => filter.key === 'channelUuids'
  )
  const selectedChannelUuids = selectedChannelFilter?.value
    ? selectedChannelFilter.value.split(', ')
    : []

  // const [reloadOpportunitiesRef, reloadOpportunities] = useCounter()

  const { showSnackbar } = useSnackbar()

  const data = useApiPost<any>(
    '/overview/v1/dashboard',
    {
      fromDate: startOfDay(datePickerState.range.startDate),
      toDate: endOfDay(datePickerState.range.endDate),
      channelUuids: selectedChannelUuids,
      ...filterOptions,
    },
    [
      datePickerState.range.startDate,
      datePickerState.range.endDate,
      JSON.stringify(selectedChannelUuids),
      JSON.stringify(filterOptions),
    ]
  )
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/networks')
  const advertisers = useApiGet('/advs/v1/minlist')
  const connections = useApiGet('/networks/v1/connections')
  // const opportunities = useApiGet('/discovers/v3/opportunities/overview', {}, [
  //   reloadOpportunitiesRef,
  // ])

  const [isEmpty, setIsEmpty] = useState(true)
  const isInDemo = useMemo(isDemo, [])

  useEffect(() => {
    const callback = async () => {
      if (!isEmpty) return

      if (accountActivityMemo === null) {
        accountActivityMemo = await checkAccountConversions()
      }

      if (accountActivityMemo) {
        setIsEmpty(false)
        return
      }

      if (data) {
        if (
          !isBlank(data.networks) ||
          !isBlank(data.advertisers) ||
          !isBlank(data.pages) ||
          !isBlank(data.trackers) ||
          !isBlank(data.channels)
        ) {
          setIsEmpty(false)
          return
        }

        if (
          !isBlank(data.totals) &&
          Object.values(data.totals).some(
            (value) => typeof value === 'number' && value > 0
          )
        ) {
          setIsEmpty(false)
          return
        }

        if (
          !isBlank(data.rows) &&
          data.rows?.some((row) =>
            Object.values(row).some(
              (value) => typeof value === 'number' && value > 0
            )
          )
        ) {
          setIsEmpty(false)
          return
        }
      }
    }
    callback()
  }, [data])

  const currentUser = getCurrentUser()

  return (
    <div className="overview2">
      {isInDemo ? (
        <Header label="Welcome to your demo dashboard!" />
      ) : (
        <>
          {currentUser?.user?.userFirstname && (
            <Header label={`Welcome ${currentUser.user.userFirstname}`} />
          )}
        </>
      )}

      <DatePicker className="in-header" />

      {!isBlank(channels) && (
        <div className="m-b-4">
          <NavFilter
            {...{
              filters,
              setFilters,
              filterLabels: {
                channelUuids: 'Site',
                advertiserUuids: 'Merchant',
                networkUuids: 'Network',
                trackerUuids: 'Campaign',
              },
              filterOptions: {
                channelUuids: {
                  '': 'All sites',
                  ...formatFilterRecords(
                    channels,
                    'channelUuid',
                    'channelName',
                    'channelDomain'
                  ),
                },
                advertiserUuids: formatFilterRecords(
                  advertisers,
                  'advertiserUuid',
                  'advertiserName',
                  'advertiserDomain'
                ),
                networkUuids: formatFilterRecords(
                  networks,
                  'networkUuid',
                  'networkName'
                ),
              },
              onRemove: (index, key) => {
                const newFilters = [...filters]
                if (key === 'channelUuids') {
                  newFilters[index].label = ''
                  newFilters[index].value = ''
                } else {
                  newFilters.splice(index, 1)
                }
                setFilters(newFilters)
              },
            }}
            hideChecks
            isMultipleChoice
          />
        </div>
      )}

      <Loadable
        data={data && channels && networks}
        placeholder={<Placeholder />}
      >
        {/*
        {!isInDemo && connections?.length && opportunities?.length && (
          <Opportunities
            data={opportunities}
            networks={networks}
            channels={channels}
            connections={connections}
            reload={reloadOpportunities}
            showSnackbar={showSnackbar}
          />
        )}
        */}
        <Stats
          {...{
            data,
            channels,
            networks,
            advertisers,
            datePickerState,
            selectedChannelUuids,
            filters,
            setFilters,
            isEmpty,
          }}
        />
        <PerformanceTable
          {...{
            data,
            datePickerState,
            selectedChannelUuids,
            isEmpty,
            currentUser,
          }}
        />
        <CommissionDropTable />
        <ClosedTable
          {...{
            channels,
            networks,
            advertisers,
          }}
        />
      </Loadable>
    </div>
  )
}

const Stats = (props) => {
  const {
    data,
    channels,
    networks,
    advertisers,
    datePickerState,
    selectedChannelUuids,
    filters,
    setFilters,
    isEmpty,
  } = props

  const { showDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()

  const isSingleDate =
    moment(datePickerState.range.startDate).format('YYYY-MM-DD') ===
    moment(datePickerState.range.endDate).format('YYYY-MM-DD')
  const showTotals = !isBlank(data.totals)

  return (
    <>
      {showTotals && (
        <div className="top-stats m-b-20">
          <TopStat
            title="Pageviews"
            icon="pageviews"
            value={data.totals.pageviews.count}
            valueType="integer"
            valueTrend={data.totals.pageviews.change}
            relevantTitle="EPM"
            relevantValue={data.totals.pageviews.epm}
            relevantValueType={data.totals.commission.currencyCode || 'DKK'}
            isEmpty={isEmpty}
          />
          <TopStat
            title="Clicks"
            icon="clicks"
            value={data.totals.clicks.count}
            valueType="integer"
            valueTrend={data.totals.clicks.change}
            subValue={data.rows.reduce(
              (result, row) => result + row.discoverCount || 0,
              0
            )}
            subValueType="integer"
            relevantTitle="CTR"
            relevantValue={data.totals.clicks.ctr}
            relevantValueType="~trend1"
            isEmpty={isEmpty}
          />
          <TopStat
            title="Conversions"
            icon="conversions"
            value={data.totals.conversions.count}
            valueType="integer"
            valueTrend={data.totals.conversions.change}
            relevantTitle="CR"
            relevantValue={data.totals.conversions.cr}
            relevantValueType="~trend1"
            isEmpty={isEmpty}
          />
          <TopStat
            title="Revenue"
            icon="revenue"
            value={data.totals.commission.value}
            valueType={data.totals.commission.currencyCode || 'DKK'}
            valueTrend={data.totals.commission.change}
            rows={data.rows}
            rowsKey="conversionTotalCommission"
            chartColor="#2BBBC6"
            relevantTitle="EPC"
            relevantValue={data.totals.commission.epc}
            relevantValueType={data.totals.commission.currencyCode || 'DKK'}
            isEmpty={isEmpty}
          />
        </div>
      )}

      <div className="top-charts m-b-20">
        <TopChart
          title="Pageviews and Clicks"
          grid="horizontal"
          rows={data.rows}
          isSingleDate={isSingleDate}
          rowKeys={[
            {
              type: 'bar',
              key: 'clickCount',
              yAxisId: 'clickCount',
              label: 'Clicks',
              color: '#FFB000',
              index: '1',
            },
            {
              type: 'bar',
              key: 'discoverCount',
              yAxisId: 'clickCount',
              label: 'Discover clicks',
              color: '#828B9E',
              index: '2',
            },
            {
              type: 'area',
              key: 'pageviewCount',
              label: 'Pageviews',
              color: '#FF8656',
              index: '0',
            },
          ]}
          isEmpty={isEmpty}
        />
        <TopChart
          title="Conversions and Revenue"
          grid="vertical"
          rows={data.rows}
          isSingleDate={isSingleDate}
          rowKeys={[
            {
              type: 'area',
              key: 'conversionCount',
              label: 'Conversions',
              color: '#6A98FD',
            },
            {
              type: 'area',
              key: 'conversionTotalCommission',
              label: 'Revenue',
              color: '#0CCFAF',
            },
          ]}
          isEmpty={isEmpty}
        />
      </div>

      <LatestStats
        {...{
          networks,
          showDrawer,
          showSnackbar,
          datePickerState,
          selectedChannelUuids,
          isEmpty,
        }}
      />
    </>
  )
}

const TopStat = (props) => {
  const {
    title,
    icon,
    value,
    valueType,
    valueTrend,
    subValue,
    subValueType,
    relevantTitle,
    relevantValue,
    relevantValueType,
    isEmpty,
  } = props

  const noData = isEmpty && !value && !valueTrend && !relevantValue

  return (
    <div className="card card-nooverflow p-x-5 p-b-3">
      <div className="column column-space-between column-fill h-100">
        <div className="row row-fill">
          <SVG src={`/images/overview2/top-${icon}.svg`} className="m-r-3" />
          <div className="flex-1">
            <div className="m-b-2">
              <div className="row row-center row-space-between row-fill overview-top-header">
                <div className="text-light text-bold">
                  {title}{' '}
                  {valueType === 'integer' ? null : (
                    <span className="o-50">{valueType}</span>
                  )}
                </div>
                <Trend valuePercentage={valueTrend} />
              </div>
            </div>
            <div>
              <span className="text-dark text-larger text-boldest text-nowrap m-r-1">
                {valueType === 'integer' ? (
                  <>{formatStat(value, valueType)}</>
                ) : (
                  <>{formatStat(value, valueType, false, true)}</>
                )}
              </span>
              {subValue ? (
                <WithTooltip
                  className="inline-block text-light text-bold m-r-1"
                  label="Discover clicks"
                  text={`(${formatStat(subValue, subValueType)})`}
                  forceShow
                />
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <hr className="m-t-4 m-b-10" />
          <span className="row row-center row-space-between text-light">
            <div>{relevantTitle}</div>
            <div>{formatStat(relevantValue, relevantValueType)}</div>
          </span>
        </div>
      </div>
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
    </div>
  )
}

const LatestStats = (props) => {
  const { selectedChannelUuids } = props

  const interval = useInterval(30000)
  const loadData = useApiPost<any>(
    '/overview/v1/livebox',
    {
      channelUuids: selectedChannelUuids,
    },
    [interval, JSON.stringify(selectedChannelUuids)]
  )
  const data = useMemoPresent(loadData) || loadData

  const loaded = data && data.pageviews && data.clicks && data.conversions
  const reset =
    data && loaded
      ? [
          data.pageviews.intervals.join(','),
          data.clicks.intervals.join(','),
          data.conversions.intervals.join(','),
        ].join(';')
      : null
  useEffect(() => {
    const cardBody: any = document.querySelector(
      '.overview-livebox > .card-body'
    )
    if (cardBody) {
      cardBody.style.transition = 'none'
      cardBody.style.opacity = 0
      setTimeout(() => {
        cardBody.style.transition = ''
        cardBody.style.opacity = 1
      }, 100)
    }
  }, [reset])

  if (!loaded) {
    return (
      <div className="row m-b-20">
        <div className="card flex-1 flex-1-5">
          <LatestChart rows={[]} color="#FF8656" />
          <div className="text-bolder m-t-3 m-b-1">
            Pageviews <span className="text-light text-bold o-60">30 min</span>
          </div>
          <div className="text-dark text-large text-boldest m-b-3">&nbsp;</div>
          <div className="card-divider m-t-0" />
          <div className="overview-latest-container flex-center">
            <SmallLoadable loaded={false} />
          </div>
        </div>

        <div className="card flex-1 flex-2-5">
          <LatestChart rows={[]} color="#FFB000" />
          <div className="text-bolder m-t-3 m-b-1">
            Clicks <span className="text-light text-bold o-60">30 min</span>
          </div>
          <div className="text-dark text-large text-boldest m-b-3">&nbsp;</div>
          <div className="card-divider m-t-0" />
          <div className="overview-latest-container flex-center">
            <SmallLoadable loaded={false} />
          </div>
        </div>

        <div className="card flex-1 flex-2-5">
          <LatestChart rows={[]} color="#6A98FD" />
          <div className="text-bolder m-t-3 m-b-1">
            Conversions{' '}
            <span className="text-light text-bold o-60">30 min</span>
          </div>
          <div className="text-dark text-large text-boldest m-b-3">&nbsp;</div>
          <div className="card-divider m-t-0" />
          <div className="overview-latest-container flex-center">
            <SmallLoadable loaded={false} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row m-b-20">
      <div className="card flex-1 flex-1-5">
        <LatestChart rows={data.pageviews.intervals} color="#FF8656" />
        <div className="text-bolder m-t-3 m-b-1">
          Pageviews <span className="text-light text-bold o-60">30 min</span>
        </div>
        <div className="text-dark text-large text-boldest m-b-3">
          {formatStat(data.pageviews.total)}
        </div>
        <div className="card-divider m-t-0" />
        <LatestPageviews {...props} />
      </div>

      <div className="card flex-1 flex-2-5">
        <LatestChart rows={data.clicks.intervals} color="#FFB000" />
        <div className="overview-live-data">Live Data</div>
        <div className="text-bolder m-t-3 m-b-1">
          Clicks <span className="text-light text-bold o-60">30 min</span>
        </div>
        <div className="text-dark text-large text-boldest m-b-3">
          {formatStat(data.clicks.total)}
        </div>
        <div className="card-divider m-t-0" />
        <LatestClicks {...props} />
      </div>

      <div className="card flex-1 flex-2-5">
        <LatestChart rows={data.conversions.intervals} color="#6A98FD" />
        <Link className="overview-show-all" href="/insights/conversions">
          View all
        </Link>
        <div className="text-bolder m-t-3 m-b-1">
          Conversions <span className="text-light text-bold o-60">30 min</span>
        </div>
        <div className="text-dark text-large text-boldest m-b-3">
          {formatStat(data.conversions.total)}
        </div>
        <div className="card-divider m-t-0" />
        <LatestConversions {...props} />
      </div>
    </div>
  )
}

const LatestChart = (props) => {
  const { rows, color } = props

  const max = Math.max(...rows.map((row) => row.value)) || 1
  const shownRows = useMemo(() => {
    const result = [...rows]
    while (result.length < 7) {
      result.unshift({
        timestamp: result[0] ? result[0].timestamp - 1 : new Date().getTime(),
        value: 0,
      })
    }
    return result
  }, [rows])

  return (
    <div className="overview-live-chart">
      {map(shownRows, (row) => (
        <div
          key={row.timestamp}
          className="live-chart-bar"
          style={{
            height: `${(row.value / max) * 14}px`,
            background: color,
          }}
        />
      ))}
    </div>
  )
}

const LatestPageviews = (props) => {
  const { selectedChannelUuids, isEmpty } = props

  const loadData = useApiPost<any>(
    '/overview/v1/pageviews/latest',
    {
      channelUuids: selectedChannelUuids,
    },
    [JSON.stringify(selectedChannelUuids)]
  )

  const data = parseDevices(loadData)
  const total = data.desktop + data.mobile + data.tablet

  const noData = isEmpty && total === 0

  return (
    <div className="overview-latest-container">
      <div className="overview-pageviews">
        {data && (
          <>
            <LatestPageviewsItem
              type="desktop"
              value={data.desktop}
              label="Desktop"
              total={total}
            />
            <LatestPageviewsItem
              type="mobile"
              value={data.mobile}
              label="Mobile"
              total={total}
            />
            <LatestPageviewsItem
              type="tablet"
              value={data.tablet}
              label="Tablet"
              total={total}
            />
          </>
        )}
      </div>
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
    </div>
  )
}

const LatestPageviewsItem = (props) => {
  const { type, value, label, total } = props

  const factor = total ? value / total : 0
  const minWidth = 80
  const maxWidth = 160
  const circleSize = minWidth + (maxWidth - minWidth) * factor

  return (
    <div
      className={`overview-pageview overview-pageview-${type}`}
      style={{
        width: circleSize,
      }}
    >
      <div>
        <div className="text-small text-bold m-b-2">{label}</div>
        <div className="text-dark text-large text-boldest">
          {formatStat(value)}
        </div>
      </div>
    </div>
  )
}

const LatestClicks = (props) => {
  const { selectedChannelUuids, showDrawer, showSnackbar, isEmpty } = props

  const interval = useInterval(15000)
  const loadData = useApiPost<any>(
    '/overview/v1/clicks/latest',
    {
      channelUuids: selectedChannelUuids,
    },
    [interval, JSON.stringify(selectedChannelUuids)]
  )
  const data = useMemoPresent(loadData) || loadData
  const diff = useMemo(() => {
    const result = []
    if (data && loadData && !isEqual(data.clicks, loadData.clicks)) {
      loadData.clicks?.forEach((click) => {
        if (
          click.clickUuid &&
          !data.clicks?.find(
            (oldClick) => click.clickUuid === oldClick.clickUuid
          )
        ) {
          result.push(click.clickUuid)
        }
      })
    }
    return result
  }, [loadData])

  const noData = isEmpty && data && data.clicks && data.clicks.length === 0

  return (
    <div key="overview-clicks" className="overview-latest-container">
      {map(data?.clicks, (click, index) => (
        <LatestClicksItem
          key={click.clickUuid || index}
          click={click}
          time={formatDate(click.clickDatetime)}
          highlight={diff.includes(click.clickUuid)}
          showDrawer={showDrawer}
          showSnackbar={showSnackbar}
        />
      ))}
      {isBlank(data?.clicks) && (
        <>
          {data === null ? (
            <div className="overview-latest-container flex-center">
              <SmallLoadable loaded={false} />
            </div>
          ) : (
            <NoDataDiv
              description="No data within the last 7 days."
              className="p-y-50"
            />
          )}
        </>
      )}
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
      <Tooltip
        id="overview2-clicks-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[data?.clicks]}
      />
    </div>
  )
}

const LatestClicksItem = (props) => {
  const { click, time, highlight, showDrawer, showSnackbar } = props

  return (
    <div
      className={varClass({
        'overview-live-item overview-click-item': true,
        'highlight': highlight,
        'cursor-pointer': !!click.clickUuid,
      })}
      onClick={() => {
        if (click.clickUuid) {
          showDrawer(
            <ConversionJourneyDrawer
              clickUuid={click.clickUuid}
              showSnackbar={showSnackbar}
            />
          )
        }
      }}
    >
      <div className="row row-center row-fill row-nowrap vertical-middle">
        <WithTooltip
          className="from"
          label={click.clickUrlPath || click.clickName || 'Unknown'}
          text={toUnicode(
            host(click.clickUrlPath || click.clickName || 'Unknown')
          )}
          tooltipId="overview2-clicks-tooltip"
        />
        <SVG src="/images/overview2/icon-arrow.svg" className="m-x-15" />
        <div className="text-dark text-small text-bold">
          {toUnicode(
            host(
              detailName(
                click.clickName ||
                  click.advertiser.advertiserName ||
                  click.advertiser.advertiserDomain
              ) || 'Unknown'
            )
          )}
        </div>
      </div>
      <div>{time}</div>
    </div>
  )
}

const LatestConversions = (props) => {
  const { selectedChannelUuids, networks, showDrawer, showSnackbar, isEmpty } =
    props

  const interval = useInterval(300000)
  const loadData = useApiPost<any>(
    '/overview/v1/conversions/latest',
    {
      channelUuids: selectedChannelUuids,
    },
    [interval, JSON.stringify(selectedChannelUuids)]
  )
  const data = useMemoPresent(loadData) || loadData
  const diff = useMemo(() => {
    const result = []
    if (data && loadData && !isEqual(data.conversions, loadData.conversions)) {
      loadData.conversions?.forEach((conversion) => {
        if (
          conversion.conversionUuid &&
          !data.conversions?.find(
            (oldConversion) =>
              conversion.conversionUuid === oldConversion.conversionUuid
          )
        ) {
          result.push(conversion.conversionUuid)
        }
      })
    }
    return result
  }, [loadData])

  const noData =
    isEmpty && data && data.conversions && data.conversions.length === 0

  return (
    <div className="overview-latest-container">
      {map(data?.conversions, (conversion) => (
        <LatestConversionsItem
          key={conversion.conversionUuid}
          conversion={conversion}
          networks={networks}
          time={formatDate(conversion.conversionDatetime)}
          highlight={diff.includes(conversion.conversionUuid)}
          showDrawer={showDrawer}
          showSnackbar={showSnackbar}
        />
      ))}
      {isBlank(data?.conversions) && (
        <>
          {data === null ? (
            <div className="overview-latest-container flex-center">
              <SmallLoadable loaded={false} />
            </div>
          ) : (
            <NoDataDiv
              description="No data within the last 7 days."
              className="p-y-50"
            />
          )}
        </>
      )}
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
      <Tooltip
        id="overview2-conversions-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[data?.conversions]}
      />
    </div>
  )
}

const LatestConversionsItem = (props) => {
  const { conversion, networks, time, highlight, showDrawer, showSnackbar } =
    props

  const unmapped =
    !conversion.conversionName &&
    !conversion.conversionUrlPath &&
    !conversion.advertiser.advertiserDomain

  return (
    <div
      className={varClass({
        'overview-live-item': true,
        'cursor-pointer': !!conversion.conversionClickUuid,
      })}
      onClick={() => {
        if (conversion.conversionClickUuid) {
          showDrawer(
            <ConversionDrawer
              conversionUuid={conversion.conversionUuid}
              networks={networks}
              showSnackbar={showSnackbar}
            />,
            {
              cardClassName: 'insight-conversion-drawer',
            }
          )
          // showDrawer(
          //   <ConversionJourney clickUuid={conversion.conversionClickUuid} />
          // )
        }
      }}
    >
      <div
        className={varClass({
          'overview-conversion-item': true,
          'highlight': highlight,
        })}
      >
        {!unmapped ? (
          <div className="row row-center row-fill row-nowrap vertical-middle">
            <WithTooltip
              className="from"
              label={
                toUnicode(conversion.conversionUrlPath) ||
                conversion.network?.networkName ||
                'Unknown'
              }
              text={toUnicode(
                host(
                  conversion.conversionUrlPath ||
                    conversion.network?.networkName ||
                    'Unknown'
                )
              )}
              tooltipId="overview2-conversions-tooltip"
            />
            <SVG src="/images/overview2/icon-arrow.svg" className="m-x-15" />
            <div className="text-dark text-small text-bold">
              {toUnicode(
                host(
                  detailName(
                    conversion.conversionName ||
                      conversion.advertiser.advertiserDomain
                  ) || 'Unknown'
                )
              )}
            </div>
          </div>
        ) : (
          <div className="row row-center row-fill row-nowrap vertical-middle">
            <div className="from">
              {conversion.network?.networkName || 'Unmapped conversion'}
            </div>
          </div>
        )}
        <div>{time}</div>
      </div>
      <div
        className={varClass({
          'overview-conversion-revenue': true,
          'highlight': highlight,
        })}
      >
        <div>{conversion.conversionCurrencyCode || 'DKK'}</div>
        <div>
          {formatStat(
            conversion.conversionCommission,
            conversion.conversionCurrencyCode,
            false,
            true
          )}
        </div>
      </div>
    </div>
  )
}
