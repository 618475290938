import React, { useState, useEffect, useRef, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd'
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend'
import { Controller, useForm } from 'react-hook-form'
import { sortBy } from 'lodash'
import { defaultChannelUuids } from './constants'
import { Placeholder } from './Placeholder'
import {
  IChannelsV1Channels,
  Header,
  Search,
  AsyncSelect,
  Select,
  Loadable,
  SmallLoadable,
  TableInfo,
  TableColumns,
  TableEmpty,
  useApiGet,
  useApiPost,
  useModal,
  useSnackbar,
  useFormSubmit,
  useSortableTable,
  useTableEmpty,
  copyToClipboard,
  navigateTo,
  navigateReload,
  plural,
  map,
  varClass,
  isBlank,
  toArray,
  inTimezone,
  newTableSort,
  filterBySearch,
  filterTableColumns,
  formatStat,
  host,
  hostpath,
  responseError,
} from '../../shared'
import { postRequest, putRequest } from '../../../services'

const tableColumnsKey = 'tools-shortener-columns'
const defaultTableColumns = [
  'urlShortName',
  'urlShortTrackingUrl',
  'channelDomain',
  'advertiserDomain',
  'clicksCount',
  'urlShortCreatedDatetime',
]

const tableLabels = {
  'urlShortName': 'Smart Link name',
  'urlShortTrackingUrl': 'Smart Link',
  'channelDomain': 'Site',
  'advertiserDomain': 'Merchant',
  'clicksCount': 'Clicks',
  'urlShortCreatedDatetime': 'Date created',
}

const urlsSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'channelDomain') {
    return [a.channel[key], b.channel[key]]
  }
  if (key === 'advertiserDomain') {
    return [a.advertiser[key], b.advertiser[key]]
  }
})

export const UrlShortener = (props) => {
  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const data = useApiGet('/tools/v1/urlshorts')
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/connections')

  return (
    <>
      <Header label="Smart Links" />
      <Loadable
        data={data && channels && networks}
        placeholder={<Placeholder />}
      >
        <DndProvider backend={HTML5Backend}>
          <Table
            data={toArray(data)}
            {...{ channels, networks, showModal, showSnackbar }}
          />
          {/*<button className="btn btn-lg btn-link m-t-5">Load more</button>*/}
        </DndProvider>
      </Loadable>
    </>
  )
}

const Table = (props) => {
  const { data, channels, networks, showModal, showSnackbar } = props

  const [columns, setColumns] = useState(() =>
    filterTableColumns(
      localStorage.getItem(tableColumnsKey) || '',
      defaultTableColumns
    )
  )

  const [draggedColumn, setDraggedColumn] = useState('')

  const swapColumns = (value1: string, value2: string) => {
    const index1 = columns.indexOf(value1)
    const index2 = columns.indexOf(value2) || 1
    if (index1 === index2) return
    if (index1 === 0 || index2 === 0) return
    const newColumns = [...columns]
    const item = newColumns[index1]
    newColumns.splice(index1, 1)
    newColumns.splice(index2, 0, item)
    setColumns(newColumns)
    localStorage.setItem(tableColumnsKey, newColumns.join(','))
  }

  const [search, setSearch] = useState('')
  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'urlShortCreatedDatetime',
    dir: 'desc',
  })

  const shownLinks = urlsSort(
    filterBySearch(data, search, (url: any) => [
      url.urlShortName,
      url.urlShortTrackingUrl.substring(
        url.urlShortTrackingUrl.lastIndexOf('/')
      ),
    ]),
    sort,
    dir
  )

  const [isEmpty, setIsEmpty] = useTableEmpty(data)

  return (
    <div className="card tools-shortener-card">
      <div className="card-body card-body-fill">
        <TableInfo>
          <Search
            value={search}
            setValue={setSearch}
            placeholder="Find smart link"
            className="search flex-1"
          />
          <div className="vertical-middle">
            <TableColumns
              storageKey={tableColumnsKey}
              columns={columns}
              setColumns={setColumns}
              availableColumns={defaultTableColumns}
              columnLabels={tableLabels}
            />
            <button
              className={varClass({
                'btn btn-dark m-l-15': true,
                'btn-gradient relative z-index-6':
                  isBlank(channels) || isBlank(networks) || isEmpty,
              })}
              onClick={() =>
                showModal(<NewLinkModal showSnackbar={showSnackbar} />)
              }
            >
              <SVG
                src="/images/icon-plus.svg"
                width={18}
                height={18}
                className="o-50 m-r-15"
              />
              Create Smart Link
            </button>
            <div className="total">
              {data?.length || 0} {plural(data.length, 'result')}
            </div>
          </div>
        </TableInfo>

        <div className="table-container-overflow">
          <table className="table table-bordered table-sortable table-hoverable">
            <thead>
              <tr className="text-nowrap">
                {columns.map((column, index) => (
                  <TableHead
                    key={column}
                    index={index}
                    value={column}
                    label={tableLabels[column]}
                    desc={
                      column === 'clicksCount' ||
                      column === 'urlShortCreatedDatetime'
                    }
                    {...{
                      toggleSort,
                      sort,
                      dir,
                      columns,
                      draggedColumn,
                      setDraggedColumn,
                      swapColumns,
                    }}
                  />
                ))}
              </tr>
            </thead>
            <tbody className="text-nowrap">
              {map(shownLinks, (url) => (
                <TableItem
                  key={url.urlShortCode}
                  {...{
                    url,
                    columns,
                    sort,
                    draggedColumn,
                    showModal,
                    showSnackbar,
                  }}
                />
              ))}
              {!isEmpty && isBlank(data) && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <DragTable
            {...{
              shownLinks,
            }}
          />
        </div>

        {isEmpty && (
          <TableEmpty
            icon="shortener"
            title="No Smart Links created yet"
            subtitle="To start using Smart Links Connect a Site and add a Network"
            buttons={{
              '/channels': 'Create site',
              '/networks': 'Create network',
            }}
            setIsEmpty={setIsEmpty}
            dataTitle="No Smart Links created yet"
            dataSubtitle="To create your first Smart Link simply click the button above"
            dataShowArrow
          />
        )}
      </div>
    </div>
  )
}

const TableHead = (props) => {
  const {
    value,
    label,
    desc,
    sort,
    dir,
    toggleSort,
    index,
    columns,
    draggedColumn,
    setDraggedColumn,
    swapColumns,
  } = props

  const ref = useRef<any>(null)

  const [{ isDragging }, dragHandle, drag] = useDrag(() => ({
    type: 'column',
    item: () => {
      setDraggedColumn(value)
      return { value }
    },
    end: (item) => {
      setDraggedColumn('')
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [{ isOver: _isOver }, drop] = useDrop({
    accept: 'column',
    hover: (item: any, monitor: any) => {
      if (!ref.current) {
        return
      }
      const value1 = item.value
      const value2 = value
      if (value1 === value2) {
        return
      }
      const index1 = columns.indexOf(value1)
      const index2 = columns.indexOf(value2)

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverQuarterX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 4

      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      if (index1 < index2 && hoverClientX < hoverMiddleX - hoverQuarterX) {
        return
      }
      if (index1 > index2 && hoverClientX > hoverMiddleX + hoverQuarterX) {
        return
      }

      swapColumns(value1, value2)
    },
    drop: (item: any) => {
      swapColumns(item.value, value)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  drag(getEmptyImage())
  drop(ref)

  if (index === 0) {
    return (
      <th
        onClick={toggleSort(value, desc ? 'desc' : 'asc')}
        ref={ref}
        className={varClass({
          'vertical-middle p-r-4': true,
          'sort-highlight': sort === value,
        })}
      >
        {draggedColumn && (
          <div
            className={varClass({
              'drop-space': true,
              'opaque': draggedColumn === columns[index],
            })}
          />
        )}
        <div className="row row-fill row-nowrap">
          <span className="column-label">
            {label}
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </span>
        </div>
      </th>
    )
  } else {
    return (
      <th
        onClick={toggleSort(value, desc ? 'desc' : 'asc')}
        ref={ref}
        className={varClass({
          'relative vertical-middle p-l-2 p-r-4': true,
          'sort-highlight': sort === value,
          'drag-highlight': draggedColumn === value,
        })}
        style={{
          color: isDragging ? 'transparent' : '',
        }}
      >
        {draggedColumn && <div className="drop-space" />}
        <div className="row row-fill row-nowrap">
          <span ref={dragHandle} className="cursor-grab m-r-10">
            <SVG src="/images/insights/icon-drag.svg" className="handle" />
          </span>
          <span className="column-label">
            {label}
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </span>
        </div>
      </th>
    )
  }
}

const TableItem = (props) => {
  const { url, columns, sort, draggedColumn, showModal, showSnackbar } = props

  const values = {
    'urlShortName': url.urlShortName,
    'urlShortTrackingUrl': url.urlShortTrackingUrl,
    'channelDomain': url.channel.channelDomain || 'Unknown',
    'advertiserDomain': url.advertiser.advertiserDomain || 'Unknown',
    'clicksCount': formatStat(url.clicksCount),
    'urlShortCreatedDatetime': inTimezone(url.urlShortCreatedDatetime),
  }

  return (
    <tr>
      {columns.map((column, index) => (
        <td
          key={column}
          className={varClass({
            'text-nowrap': true,
            'p-0': column === 'urlShortTrackingUrl',
            'text-right': column === 'clicksCount',
            'sort-highlight': sort === column,
            'drag-highlight': draggedColumn === column,
          })}
        >
          {(() => {
            switch (column) {
              case 'urlShortName':
                return (
                  <button
                    className="name"
                    onClick={() => {
                      showModal(
                        <EditLinkModal url={url} showSnackbar={showSnackbar} />
                      )
                    }}
                  >
                    {values[column]}
                    <SVG src="/images/icon-edit.svg" className="arrow m-l-3" />
                  </button>
                )
              case 'urlShortTrackingUrl':
                return (
                  <button
                    className="link ctc p-x-6 p-y-5"
                    onClick={() => {
                      copyToClipboard(values[column])
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    {values[column]}
                    <SVG src="/images/icon-copy.svg" className="m-l-3" />
                  </button>
                )

              case 'channelDomain':
                return (
                  <div
                    className="badge badge-hoverable text-smaller text-normal p-y-05"
                    onClick={(event) =>
                      navigateTo(event, `/sites/${url.channel.channelUuid}`)
                    }
                  >
                    {values[column]}
                  </div>
                )

              case 'advertiserDomain':
                return (
                  <div
                    className="badge badge-hoverable text-smaller text-normal p-y-05"
                    onClick={(event) =>
                      navigateTo(
                        event,
                        `/merchants/${url.advertiser.advertiserUuid}`
                      )
                    }
                  >
                    {values[column]}
                  </div>
                )

              default:
                return <TableValue values={values} column={column} />
            }
          })()}
        </td>
      ))}
    </tr>
  )
}

const TableValue = (props) => {
  const { values, column } = props

  if (column === 'clicksCount') {
    return (
      <div className={`value-badge value-badge-clickCount`}>
        {values[column]}
      </div>
    )
  } else {
    return values[column]
  }
}

export const NewLinkModal = (props) => {
  const { showSnackbar, onDone } = props

  const { hideModal } = useModal()

  const advertisers = useApiGet('/advs/v1/minlist', {
    hasActiveCampaigns: true,
  })
  const channels = useApiGet<IChannelsV1Channels[]>('/channels/v1/list')

  const defaultInitialChannel = channels?.find((channel) =>
    defaultChannelUuids.includes(channel.channelUuid)
  )
  const form = useForm()

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    const response = await postRequest('/tools/v1/urlshorts', values)

    if (response) {
      if (response.code === 200) {
        hideModal()
        if (onDone) {
          onDone()
        } else {
          navigateReload()
        }
      } else {
        showSnackbar(responseError(response), { type: 'alert' })
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Create Smart Link</div>
      </div>
      <div className="card-body">
        <SmallLoadable loaded={advertisers && channels} height={287}>
          <LinkForm
            {...{
              form,
              onSubmit,
              submitting,
              advertisers,
              channels,
              defaultInitialChannel,
            }}
            submitText="Create"
          />
        </SmallLoadable>
      </div>
    </>
  )
}

const EditLinkModal = (props) => {
  const { url, showSnackbar } = props

  const { hideModal } = useModal()

  const advertisers = useApiGet('/advs/v1/minlist', {
    hasActiveCampaigns: true,
  })
  const channels = useApiGet('/channels/v1/list')

  const form = useForm({
    defaultValues: {
      urlShortChannelUuid: url.channel.channelUuid,
      urlShortAdvertiserUuid: url.advertiser.advertiserUuid,
      urlShortName: url.urlShortName,
      urlShortChannelUrl: url.urlShortChannelUrl,
      urlShortTargetUrl: url.urlShortTargetUrl,
      urlShortCampaignUuid: url.campaign?.campaignUuid,
    },
  })

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    const response = await putRequest(
      `/tools/v1/urlshorts/${url.urlShortCode}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        hideModal()
        navigateReload()
      } else {
        showSnackbar(responseError(response), { type: 'alert' })
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Edit Smart Link</div>
      </div>
      <div className="card-body">
        <SmallLoadable loaded={advertisers && channels} height={287}>
          <LinkForm
            {...{ form, onSubmit, submitting, advertisers, channels }}
            initialChannel={url.channel}
            initialAdvertiser={url.advertiser}
            initialCampaign={url.campaign}
            submitText="Update"
          />
        </SmallLoadable>
      </div>
    </>
  )
}

const LinkForm = (props) => {
  const {
    form,
    onSubmit,
    submitting,
    advertisers,
    channels,
    submitText,
    initialChannel,
    defaultInitialChannel,
    initialAdvertiser,
    initialCampaign,
  } = props

  const { register, control, setValue, getValues, handleSubmit } = form

  const [selectedChannelUuid, setSelectedChannelUuid] = useState(
    initialChannel?.channelUuid
  )
  const [selectedAdvertiserUuid, setSelectedAdvertiserUuid] = useState(
    initialAdvertiser?.advertiserUuid
  )
  const [showCampaignSelect, setShowCampaignSelect] = useState(
    initialCampaign ? !!initialCampaign?.campaignUuid : false
  )
  const campaigns = useApiPost<any>(
    `/advs/v1/advs/${selectedAdvertiserUuid}/camps`,
    { channelUuid: selectedChannelUuid || undefined },
    [selectedChannelUuid, selectedAdvertiserUuid]
  )

  useEffect(() => {
    if (defaultInitialChannel) {
      setValue('urlShortChannelUuid', defaultInitialChannel.channelUuid)
      setSelectedChannelUuid(defaultInitialChannel.channelUuid)
      setValue(
        'urlShortChannelUrl',
        `https://${defaultInitialChannel.channelDomain}/`
      )
    }
  }, [])

  const channelOptions = useMemo(() => {
    if (!channels || !Array.isArray(channels)) return []
    return sortBy(
      map(channels, (channel) => ({
        value: channel.channelUuid,
        label:
          channel.channelType === 'App' ||
          channel.channelType === 'Social media'
            ? channel.channelName || host(channel.channelDomain)
            : host(channel.channelDomain) || channel.channelName,
        domain: host(channel.channelDomain),
      })),
      (channelOption) => channelOption.label
    )
  }, [channels])
  const channelOptionHints = useMemo(() => {
    if (!channels || !Array.isArray(channels)) return []
    return channels?.reduce((result, channel) => {
      result[channel.channelUuid] =
        channel.channelType === 'App' || channel.channelType === 'Social media'
          ? hostpath(channel.channelDomain) || channel.channelName
          : channel.channelName || hostpath(channel.channelDomain)
      return result
    }, {})
  }, [channels])
  const advertiserOptions = useMemo(() => {
    if (!advertisers || !Array.isArray(advertisers)) return []
    return advertisers
      ?.filter((advertiser) => advertiser.advertiserDomain)
      ?.map((advertiser) => ({
        value: advertiser.advertiserUuid,
        label: host(advertiser.advertiserDomain),
      }))
  }, [advertisers])
  const campaignOptions = useMemo(() => {
    if (!campaigns) {
      return false
    }

    if (!Array.isArray(campaigns) || isBlank(campaigns)) {
      return []
    } else {
      return [
        { value: undefined, label: 'No campaign' },
        ...campaigns
          .filter((campaign) => campaign.campaign.campaignUuid)
          .map((campaign) => ({
            value: campaign.campaign.campaignUuid,
            label: `${[
              campaign.channels
                ?.map((channel) => channel.channelName)
                .join(', '),
              campaign.campaign.campaignName,
              campaign.network.networkName,
            ]
              .filter((str) => !!str)
              .join(' - ')} [${campaign.campaign.campaignId}]`,
          })),
      ]
    }
  }, [campaigns])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tool-shortener-form">
      <div>
        {submitText === 'Create' && (
          <div className="m-b-6">
            Create a Smart Link to use on social media or other platforms where
            you need a non-affiliate looking URL.
          </div>
        )}

        <div className="m-b-20">
          <div className="control w-100">
            <label>Link name</label>
            <input
              type="text"
              {...register('urlShortName', {
                required: true,
              })}
            />
          </div>
        </div>

        <div className="row row-bottom m-b-20 select-input">
          <div className="control control-select2 control-focusless">
            <label>From channel URL</label>
            <Controller
              render={({ field: { onChange, value, name, ref } }) => (
                <div data-tip data-for="urlshort-tooltip">
                  <Select
                    inputRef={ref}
                    placeholder="Select site"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        marginBottom: 6,
                        borderRadius: 5,
                        color: state.isSelected
                          ? 'var(--text-dark)'
                          : 'var(--text-primary)',
                        background: state.isSelected
                          ? 'var(--card-border)'
                          : 'transparent',
                        fontWeight: 500,
                        cursor: 'pointer',

                        '&:hover': {
                          background: 'var(--card-border)',
                        },

                        '&:after':
                          channelOptionHints[state.value] &&
                          channelOptionHints[state.value] !== state.label
                            ? {
                                content: `'${channelOptionHints[state.value]}'`,
                                display: 'block',
                                marginTop: 2,
                                fontSize: 12,
                                fontWeight: 400,
                              }
                            : {},
                      }),
                    }}
                    filterOption={(
                      candidate: { label: string; value: string; data: any },
                      input: string
                    ) => {
                      if (input) {
                        return (
                          candidate.label
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          (channelOptionHints[candidate.value] &&
                            channelOptionHints[candidate.value]
                              .toLowerCase()
                              .includes(input.toLowerCase()))
                        )
                      } else {
                        return true
                      }
                    }}
                    options={channelOptions}
                    value={channelOptions.find(
                      (option) => option.value === value
                    )}
                    onChange={(element) => {
                      const option = channelOptions.find(
                        (option) => option.value === element.value
                      )
                      onChange(option.value)
                      setSelectedChannelUuid(option.value)
                      setValue(
                        'urlShortChannelUrl',
                        `https://${option.domain || option.label}/`
                      )
                    }}
                  />
                </div>
              )}
              name="urlShortChannelUuid"
              rules={{ required: true }}
              control={control}
              defaultValue={initialChannel?.channelUuid || ''}
            />
          </div>
          <div className="control control-url control-focusless">
            <input
              type="text"
              className="w-100"
              {...register('urlShortChannelUrl', {
                onBlur: (event) => {
                  const value = event.target.value || ''
                  const domain = value
                    .trim()
                    .replace(/^https?:\/\//, '')
                    .replace(/[/#?].*$/g, '')
                  const domainVariants = [
                    domain,
                    domain.replace(/^[^.]+\./, ''),
                  ]
                  const channelOption = channelOptions.find(
                    (option) =>
                      domainVariants.includes(option.domain) ||
                      domainVariants.includes(option.label)
                  )
                  if (channelOption) {
                    setValue('urlShortChannelUuid', channelOption.value)
                    setSelectedChannelUuid(channelOption.value)
                  }
                },
              })}
            />
          </div>
        </div>

        <div className="row row-bottom m-b-20 select-input">
          <div className="control control-select2 control-focusless">
            <label>To merchant URL</label>
            <Controller
              render={({ field: { onChange, value, name, ref } }) => (
                <AsyncSelect
                  inputRef={ref}
                  placeholder="Select merchant"
                  options={advertiserOptions}
                  loadOptions={(inputValue, callback) => {
                    callback(
                      advertiserOptions
                        .filter(
                          (advertiserOption) =>
                            advertiserOption.label
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) !== -1
                        )
                        .slice(0, 30)
                    )
                  }}
                  defaultOptions={true}
                  value={advertiserOptions.find(
                    (option) => option.value === value
                  )}
                  onChange={(element) => {
                    const option = advertiserOptions.find(
                      (option) => option.value === element.value
                    )
                    if (option) {
                      onChange(option.value)
                      setValue('urlShortTargetUrl', `https://${option.label}/`)
                      setValue('urlShortCampaignUuid', '')
                      setSelectedAdvertiserUuid(option.value)
                      setShowCampaignSelect(false)
                    }
                  }}
                />
              )}
              name="urlShortAdvertiserUuid"
              rules={{ required: true }}
              control={control}
              defaultValue={initialAdvertiser?.advertiserUuid}
            />
          </div>
          <div className="control control-url control-focusless">
            <input
              type="text"
              className="w-100"
              {...register('urlShortTargetUrl', {
                onBlur: (event) => {
                  const value = event.target.value || ''
                  const domain = value
                    .trim()
                    .replace(/^https?:\/\//, '')
                    .replace(/[/#?].*$/g, '')
                  const domainVariants = [
                    domain,
                    domain.replace(/^[^.]+\./, ''),
                  ]
                  const advertiserOption = advertiserOptions.find((option) =>
                    domainVariants.includes(option.label)
                  )
                  if (advertiserOption) {
                    setValue('urlShortAdvertiserUuid', advertiserOption.value)
                    setSelectedAdvertiserUuid(advertiserOption.value)
                  }
                },
              })}
            />
          </div>
        </div>

        {selectedAdvertiserUuid && (
          <div className="m-b-20 campaign-select">
            {showCampaignSelect ? (
              <SmallLoadable loaded={campaignOptions}>
                {isBlank(campaignOptions) ? (
                  <div className="text-light text-smaller text-center p-t-2">
                    No campaigns found
                  </div>
                ) : (
                  <div className="control control-select2 control-focusless w-100">
                    <label>Campaign</label>
                    <Controller
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          inputRef={ref}
                          placeholder="Select campaign"
                          menuPlacement="top"
                          options={campaignOptions}
                          value={
                            campaignOptions &&
                            campaignOptions.find(
                              (option) => option.value === value
                            )
                          }
                          onChange={(element) => {
                            if (campaignOptions) {
                              const option = campaignOptions.find(
                                (option) => option.value === element.value
                              )
                              if (option) {
                                onChange(option.value)
                              }
                            }
                          }}
                        />
                      )}
                      name="urlShortCampaignUuid"
                      // rules={{ required: true }}
                      control={control}
                      defaultValue={initialCampaign?.campaignUuid}
                    />
                  </div>
                )}
              </SmallLoadable>
            ) : (
              <button
                onClick={() => setShowCampaignSelect(true)}
                className="link text-light text-smaller vertical-middle"
              >
                <SVG src="/images/icon-plus-color.svg" className="m-r-15" />
                Connect to specific campaign
              </button>
            )}
          </div>
        )}
      </div>

      <div className="text-right">
        <button type="submit" className="btn btn-primary" disabled={submitting}>
          {submitText}
        </button>
      </div>
    </form>
  )
}

const DragTable = (props) => {
  const { shownLinks } = props

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  const columns = [item?.value]

  return (
    <div
      className="drag-card"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
        pointerEvents: 'none',
        zIndex: 100,
      }}
    >
      <div className="table-container-overflow">
        <table
          className="table table-bordered table-sortable drag-table relative"
          style={{
            position: 'relative',
            width: 100,
            height: 100,
          }}
        >
          <thead className="text-nowrap">
            <tr>
              <th className="relative vertical-middle p-l-2 p-r-4 drag-highlight">
                <div className="row row-space-between row-nowrap">
                  <span className="cursor-grab text-blue m-r-10">
                    <SVG
                      src="/images/insights/icon-drag.svg"
                      className="handle"
                    />
                  </span>
                  <span className="column-label">
                    {tableLabels[item?.value]}
                    <SVG src="/images/insights/caret.svg" className="m-l-1" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {map(shownLinks, (url) => (
              <DragTableItem key={url.urlShortCode} {...{ url, columns }} />
            ))}
            {isBlank(shownLinks) && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const DragTableItem = (props) => {
  const { url, columns } = props

  const values = {
    'urlShortName': url.urlShortName,
    'urlShortTrackingUrl': url.urlShortTrackingUrl,
    'channelDomain': url.channel.channelDomain || '',
    'advertiserDomain': url.advertiser.advertiserDomain || '',
    'clicksCount': formatStat(url.clicksCount),
  }

  const column = columns[0]

  return (
    <tr>
      <td
        className={varClass({
          'text-nowrap text-right': true,
        })}
      >
        {(() => {
          switch (column) {
            case 'urlShortTrackingUrl':
              return (
                <button className="link ctc">
                  {values[column]}
                  <SVG src="/images/icon-copy.svg" className="m-r-2" />
                </button>
              )
            default:
              return values[column]
          }
        })()}
      </td>
    </tr>
  )
}
