import React, { useState } from 'react'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import {
  useApiGet,
  Header,
  Loadable,
  SearchSubmit,
  varClass,
  useModal,
  useSnackbar,
  useSortableTable,
  copyToClipboard,
  useCounter,
  NavFilter,
  capitalize,
} from '../shared'
import { FeedFormModal } from './Form'

export const formats = {
  adtraction: 'Adtraction',
  awin: 'Awin',
  daisycon: 'Daisycon',
  partnerads: 'Partner-ads',
  tradedoubler: 'Tradedoubler',
  tradetracker: 'Tradetracker',
}

export const formatExtensions = {
  adtraction: 'XML',
  awin: 'CSV',
  daisycon: 'JSON',
  partnerads: 'XML',
  tradedoubler: 'JSON',
  tradetracker: 'XML',
}

const filterLabels = {
  'country': 'Country',
  'feedFormat': 'Network',
  'status': 'Status',
}

const filterOptions = {
  'country': 'input',
  'feedFormat': {
    '': 'All',
    ...formats,
  },
  'status': {
    '': 'All',
    'Ready': 'Ready',
    'Error': 'Error',
  },
}

export const Feeds = (props) => {
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState([
    {
      key: 'country',
      value: '',
    },
    {
      key: 'feedFormat',
      value: '',
    },
    {
      key: 'status',
      value: '',
    },
  ])
  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'createdAt',
    dir: 'desc',
  })
  const [feedsRef, reloadFeeds] = useCounter()

  const feeds = useApiGet<any>(
    '/feeds/v1/xml',
    {
      search,
      filters: filters
        .filter((filter) => filter.key && filter.value)
        .map((filter) => [filter.key, filter.value].join('='))
        .join(','),
      sort,
      sortDir: dir,
      limit: 1000,
    },
    [search, filters, sort, dir, feedsRef]
  )

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  return (
    <>
      <Header label="Product feeds" />
      <div className="card">
        <TableNav
          {...{
            search,
            setSearch,
            filters,
            setFilters,
            reloadFeeds,
            showModal,
            showSnackbar,
          }}
        />
        <Loadable data={feeds}>
          <Table
            {...{
              feeds: feeds?.data || [],
              total: feeds?.total || 0,
              sort,
              dir,
              toggleSort,
              reloadFeeds,
              showModal,
              showSnackbar,
            }}
          />
        </Loadable>
      </div>
    </>
  )
}

const TableNav = (props) => {
  const {
    search,
    setSearch,
    filters,
    setFilters,
    reloadFeeds,
    showModal,
    showSnackbar,
  } = props

  return (
    <div className="p-y-20 m-t--3 m-b-10">
      <div className="row row-space-between p-t-05 m-t-4 m-b-3">
        <SearchSubmit
          value={search}
          setValue={setSearch}
          placeholder="Search feed"
          className="search w-40"
          inputClassName="w-100 p-r-8"
          highlight
        />
        <button
          className={varClass({
            'btn btn-primary': true,
          })}
          onClick={() =>
            showModal(
              <FeedFormModal
                feed={null}
                reloadFeeds={reloadFeeds}
                showSnackbar={showSnackbar}
              />
            )
          }
        >
          <SVG
            src="/images/icon-plus2.svg"
            width={18}
            height={18}
            className="m-r-15"
          />
          Add new feed
        </button>
      </div>
      <NavFilter
        {...{
          filters,
          setFilters,
          filterLabels,
          filterOptions,
        }}
        onRemove={(_, key) => {
          const newFilters = filters.map((item) => {
            if (item.key === key) {
              return { ...item, value: '', label: '' }
            }
            return { ...item }
          })
          setFilters(newFilters)
        }}
        hideAdd
      />
    </div>
  )
}

const Table = (props) => {
  const { feeds, sort, dir, toggleSort, reloadFeeds, showModal, showSnackbar } =
    props

  const headProps = { sort, dir, toggleSort }

  return (
    <div className="table-container-overflow m-x--6">
      <table className="table table-bordered table-sortable table-hoverable">
        <thead className="text-nowrap">
          <tr>
            <TableHead
              value="name"
              label="Vendor"
              className="w-30"
              {...headProps}
            />
            <TableHead
              value="domain"
              label="Domain"
              className="w-20"
              {...headProps}
            />
            <TableHead
              value="feedFormat"
              label="Network"
              className="w-20"
              {...headProps}
            />
            <TableHead
              value="country"
              label="Country"
              className="w-20"
              {...headProps}
            />
            <TableHead
              value="uuid"
              label="Link"
              className="w-20"
              {...headProps}
            />
            <TableHead
              value="lastCompiledAt"
              label="Last compiled date"
              className="w-20"
              desc
              {...headProps}
            />
            <TableHead
              value="lastRequestedAt"
              label="Last requested date"
              className="w-20"
              desc
              {...headProps}
            />
            <TableHead
              value="createdAt"
              label="Created date"
              className="w-20"
              desc
              {...headProps}
            />
          </tr>
        </thead>
        <tbody>
          {feeds.map((feed) => (
            <TableItem
              key={feed.uuid}
              feed={feed}
              sort={sort}
              reloadFeeds={reloadFeeds}
              showModal={showModal}
              showSnackbar={showSnackbar}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const TableHead = (props) => {
  const { value, label, className, desc, sort, dir, toggleSort } = props

  return (
    <th
      className={varClass({
        'cursor-pointer': true,
        [className]: !!className,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const { feed, sort, reloadFeeds, showModal, showSnackbar } = props

  return (
    <tr>
      <td
        className={varClass({
          'sort-highlight': sort === 'name',
        })}
      >
        <button
          className="name"
          onClick={() => {
            showModal(
              <FeedFormModal
                feed={feed}
                reloadFeeds={reloadFeeds}
                showSnackbar={showSnackbar}
              />
            )
          }}
        >
          <span
            title={feed.name}
            className="vertical-middle"
            style={{
              maxWidth: 500,
              marginRight: 16,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {feed.name}
            <FeedStatus status={feed.status} message={feed.message} />
          </span>
          <SVG src="/images/networks/icon-edit2.svg" className="arrow" />
        </button>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'domain',
        })}
      >
        {feed.domain}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'feedFormat',
        })}
      >
        {feed.feedFormat}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'country',
        })}
      >
        {feed.country}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'uuid',
        })}
      >
        <button
          className="btn btn-link btn-sm text-secondary text-left text-nowrap text-smaller text-new-light background-new-gray-light border-none"
          style={{
            maxWidth: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onClick={() => {
            copyToClipboard(feed.link)
            showSnackbar('Copied to clipboard')
          }}
        >
          {feed.link}
        </button>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'lastCompiledAt',
        })}
        title={
          feed.lastCompiledAt
            ? moment(feed.lastCompiledAt).format('YYYY-MM-DD HH:mm:ss')
            : ''
        }
      >
        {feed.lastCompiledAt
          ? moment(feed.lastCompiledAt).format('YYYY-MM-DD')
          : 'Never'}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'lastRequestedAt',
        })}
        title={
          feed.lastRequestedAt
            ? moment(feed.lastRequestedAt).format('YYYY-MM-DD HH:mm:ss')
            : ''
        }
      >
        {feed.lastRequestedAt
          ? moment(feed.lastRequestedAt).format('YYYY-MM-DD')
          : 'Never'}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'createdAt',
        })}
        title={moment(feed.createdAt).format('YYYY-MM-DD HH:mm:ss')}
      >
        {moment(feed.createdAt).format('YYYY-MM-DD')}
      </td>
    </tr>
  )
}

const statusColors = {
  Ready: '#3FAC73',
  Updating: '#FFB000',
  Error: '#EB0038',
}

const FeedStatus = (props) => {
  const { status, message } = props

  return (
    <div
      title={message ? capitalize(message) : status}
      className="inline-row row-center p-15 m-l-1"
    >
      <div
        style={{
          width: 6,
          height: 6,
          borderRadius: '3px',
          background: statusColors[status] || '#6B7280',
        }}
      />
    </div>
  )
}
