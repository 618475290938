import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { sortBy, uniq } from 'lodash'
import {
  Commission,
  formatCommission,
  Loadable,
  map,
  newTableSort,
  useApiGet,
  useCounter,
  useSortableTable,
  varClass,
} from '../shared'
import { postRequest, deleteRequest } from '../../services'

const tableSort = newTableSort(
  (a: any, b: any, key: string, dir: 'asc' | 'desc') => {
    switch (key) {
      case 'program': {
        const programNamesA = a.trackers
          .map((tracker) => tracker.trackerName)
          .sort()
        const programNamesB = b.trackers
          .map((tracker) => tracker.trackerName)
          .sort()
        if (dir === 'desc') {
          programNamesA.reverse()
          programNamesB.reverse()
        }
        return [
          a.network.networkName + programNamesA[0],
          b.network.networkName + programNamesB[0],
        ]
      }
      case 'commission':
        return [
          a.programCommissionFlat || a.programCommissionPercentage,
          b.programCommissionFlat || b.programCommissionPercentage,
        ]
      case 'channels': {
        const channelNamesA = a.channels
          .map((channel) => channel.channelName)
          .sort()
        const channelNamesB = b.channels
          .map((channel) => channel.channelName)
          .sort()
        if (dir === 'desc') {
          channelNamesA.reverse()
          channelNamesB.reverse()
        }
        return [channelNamesA[0], channelNamesB[0]]
      }
      case 'status': {
        const statusA =
          a.trackers.some((tracker) => tracker.trackerIsCreatorDefault) ||
          a.trackers.some((tracker) => tracker.trackerIsCreatorOnly)
        const statusB =
          b.trackers.some((tracker) => tracker.trackerIsCreatorDefault) ||
          b.trackers.some((tracker) => tracker.trackerIsCreatorOnly)
        return [statusA, statusB]
      }
    }
  }
)

export const MappingModal = (props) => {
  const [refreshRef, refresh] = useCounter()
  const data = useApiGet<any>(
    `/creators/v1/mappings/`,
    {
      advertiserUuid: props.advertiserUuid,
      creatorId: props.creatorId,
    },
    [refreshRef]
  )

  if (!data) {
    return (
      <>
        <div className="card-body">
          <Loadable loaded={false} />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="card-header">
        <div className="text-bolder">
          <span>{data?.advertiser?.advertiserDomain}</span>
          <span className="m-x-2">{' - '}</span>
          <span>{data?.creator?.creatorName}</span>
        </div>
      </div>
      <div className="card-body">
        {data && (
          <Table
            data={data}
            advertiserUuid={props.advertiserUuid}
            creatorId={props.creatorId}
            refresh={refresh}
          />
        )}
      </div>
    </>
  )
}

const Table = (props) => {
  const { data, advertiserUuid, creatorId, refresh } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'networkName',
    dir: 'asc',
  })

  const shownPrograms = tableSort(data.programs, sort, dir)

  const headProps = { sort, dir, toggleSort }
  const itemProps = { advertiserUuid, creatorId, refresh }

  return (
    <div className="table-container-overflow m-x--6 m-y--5">
      <table className="table table-bordered table-sortable">
        <thead>
          <tr>
            <TableHead value="program" label="Program" {...headProps} />
            <TableHead value="commission" label="Commission" {...headProps} />
            <TableHead value="channels" label="Sites" {...headProps} />
            <TableHead value="status" label="Status" {...headProps} />
            <th />
          </tr>
        </thead>
        <tbody>
          {map(shownPrograms, (program) => (
            <TableItem
              key={program.trackerProgramId}
              program={program}
              creator={data.creator}
              mappings={data.mappings}
              {...itemProps}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort, className } = props

  const isSortable = !!toggleSort

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
        [className]: !!className,
      })}
      onClick={toggleSort?.(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {isSortable ? (
          <>
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </>
        ) : null}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const { program, creator, mappings, advertiserUuid, creatorId, refresh } =
    props

  const trackerUuids = program.trackers.map((tracker) => tracker.trackerUuid)
  const names = uniq(program.trackers.map((tracker) => tracker.trackerName))

  const [showAllChannels, setShowAllChannels] = useState(false)
  const channels = sortBy(
    program.channels || [],
    (channel) => channel.channelName
  )
  const shownChannels = []
  if (showAllChannels) {
    shownChannels.push(...channels)
  } else {
    shownChannels.push(...channels.slice(0, 5))
  }
  const canShowAllChannels = channels.length !== shownChannels.length

  const isCreatorDefault = program.trackers.some(
    (tracker) => tracker.trackerIsCreatorDefault
  )
  const isCreatorOnly = program.trackers.some(
    (tracker) => tracker.trackerIsCreatorOnly
  )
  const isMapped = !!mappings.find((mapping) =>
    trackerUuids.includes(mapping.creatorMappingTrackerUuid)
  )

  const createMapping = async () => {
    await postRequest('/creators/v1/mappings', {
      advertiserUuid: advertiserUuid,
      creatorId: creatorId,
      programId: program.trackerProgramId,
    })
    refresh()
  }

  const removeMapping = async () => {
    await deleteRequest('/creators/v1/mappings', {
      advertiserUuid: advertiserUuid,
      creatorId: creatorId,
    })
    refresh()
  }

  return (
    <tr>
      <td>
        <div className="text-bold m-b-1">{program.network.networkName}</div>
        {map(names, (name: string) => (
          <div key={name}>{name}</div>
        ))}
      </td>
      <td>
        <Commission
          type={program.trackerCommissionType}
          value={formatCommission(
            program.programCommissionType,
            program.programCommissionFlat,
            program.programCommissionPercentage,
            program.programCommissionCurrencyCode || 'DKK',
            true
          )}
        />
      </td>
      <td>
        {map(shownChannels, (channel) => (
          <span
            key={channel.channelUuid}
            className="badge badge-hoverable text-smaller text-nowrap m-05"
          >
            {channel.channelName}
          </span>
        ))}
        {canShowAllChannels && (
          <div className="cursor-pointer m-t-2">
            <span
              className="text-light text-smaller text-bold background-new-gray-light p-x-2 p-y-05 border-round"
              onClick={() => setShowAllChannels(!showAllChannels)}
            >
              {showAllChannels
                ? 'Hide'
                : `+${channels.length - shownChannels.length} sites`}
            </span>
          </div>
        )}
      </td>
      <td>
        {isMapped ? (
          <div
            className="text-small text-dark text-bold"
            title={`${creator.creatorName} is assigned to this program`}
          >
            Assigned
          </div>
        ) : null}
        {isCreatorDefault ? (
          <div className="text-small text-light">Default for creators</div>
        ) : null}
        {isCreatorOnly ? (
          <div className="text-small text-light">Creators only</div>
        ) : null}
      </td>
      <td className="static">
        <div>
          {isMapped ? (
            <button
              className="link"
              onClick={() => {
                removeMapping()
              }}
            >
              Unassign
            </button>
          ) : (
            <button
              className="link"
              onClick={() => {
                createMapping()
              }}
            >
              Assign
            </button>
          )}
        </div>
      </td>
    </tr>
  )
}
